import {useState, useEffect} from 'react'
import Breadcrumb from '../Components/Breadcrumb';
import { useAxios } from '../Hooks/useAxios';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinner } from '../Components/LoadingSpinner';


type Parts = {
    id:string,
    name: string,
    catalogNo: string,
    price:50,
    quantity: 6,
    product: Object,
    availability: number
}

export const PartsAndConsumable = () => {
    useSetPageTitle("Parts & Consumables");
    const [parts, setParts] = useState<Parts[]>();
  
    const {response} = useAxios({
        method: 'get',
        url: '/api/part'
    })

    useEffect(()=>{
        if(response != null){
            setParts(response.data.result.parts)
        }
    },[response])


  return (
    <div className=''>
        <div className=' xl:px-32 lg:px-20 md:px-10 px-5'>
        <div className='md:my-5 my-3'>
            <h2 className='font-bold text-xl md:text-2xl'>Parts & Consumables</h2>  
            <Breadcrumb />
        </div>
        </div>
        <div>

        <div className='bg-primary bg-opacity-[0.15] xl:px-32 lg:px-20 md:px-10 px-5 py-10'>
            {parts ? <table className='w-full text-start text-sm md:text-base parts-table my-5'>
                <thead className='bg-primary text-white  text-start'>
                    <tr >
                        <th className='text-start ps-1'>PRODUCT NAME</th>
                        <th className=''></th>
                        <th className='text-start'>CATALOG NUMBER</th>
                        <th className='text-start'>PRICES</th>
                        <th className='text-start'>QTY</th>
                    </tr>
                </thead>
                <tbody className='font-medium'>
                    
                    {parts && parts.length > 0 ? (parts.map((part, index)=>{
                        return (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-primary bg-opacity-[0.15]' : ''}`}>
                                <td>
                                    <div className='ps-1'>

                                    {part.name}
                                    </div>
                                </td>
                                <td>
                                {!part.availability && <FontAwesomeIcon className='text-red-600' title='Not Available' icon={faCircleMinus} />}
                                </td>
                                <td>
                                    {part.catalogNo}
                                </td>
                                <td>
                                    ${part.price}
                                </td>
                                <td>
                                    {part.quantity}
                                </td>
                            </tr>
                        )
                    })) : (
                        <td colSpan={4} rowSpan={3} className='text-center text-lg'><span className=''> No Parts to Display </span></td>
                    )}
                </tbody>
            </table> : <LoadingSpinner />}
        </div>
        </div>
    </div>
  )
}