import  { useState } from 'react'
import { InputField } from '../../Components/InputField'
import { CustomButton } from '../../Components/CustomButton'
import {  useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../Hooks/useAuthContext';
import { axiosInstance } from '../../Interceptors/authInterceptor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { JSEncrypt } from "jsencrypt";
import { Key } from '../../key';


export const AdminLogin = () => {
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    // eslint-disable-next-line
    const [err, setErr] = useState<string>();
    const { dispatch} = useAuthContext();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);


    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(Key);
    

    const handleSubmit = async()=>{
    //    var encryptedUsername = encrypt.encrypt(username as string);
    //    var encryptedPassword = encrypt.encrypt(password as string);


        try{
            setLoading(true);
            const user = await axiosInstance.post("/auth/login", {
                username, password
            },{
                withCredentials:true
            }); 
            if(user.status === 200){
                
                dispatch({type:"LOGIN",payload:user.data})
                localStorage.setItem('user', JSON.stringify(user.data));
                if(user.data.role === '0'){
                    navigate('/admin/products');
                } else {
                    navigate('/admin/blog');
                }
            }
        } catch(e: any){
            setLoading(false)
            if(e.response.status === 401 || e.response.status === 404){
                toast.error("Invalid Credentials");
            } else {
                toast.error("Something went wrong");
            }
        }
    }

  return (
    <>
        <div className='absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col gap-3 w-[50%] bg-primary bg-opacity-[0.15] px-10 py-24 shadow-xl'>
            <h1 className='text-2xl font-bold text-center mb-10 text-primary'>Admin Login</h1>
            
            <InputField fieldName='username' placeholder='Username' onChange={(e)=>{setUsername(e.target.value)}} type='text' className='w-[70%] self-center' />            
            <InputField fieldName='password' placeholder='Password' onChange={(e)=>{setPassword(e.target.value)}} type='password' className='w-[70%] self-center' />   
            {err && <span className='text-red-500 text-center'>{err}</span>}
           
            <CustomButton title='Login' type='submit' loading={loading} className='w-[70%] self-center mt-5' onClick={handleSubmit} /> 
        </div>
            <ToastContainer theme='colored'/>
    </>

  )
}
