import  {  AxiosHeaders } from "axios";
import { useEffect, useState } from "react";
import { axiosInstance, useAxiosInterceptor } from "../Interceptors/authInterceptor";

type AxiosProps = {
    url: string,
    method: 'get' | 'post' | 'delete' | 'patch' | 'put',
    body?: object | null,
    headers?: AxiosHeaders
}

type AxiosResult = {
    response: any,
    error: string,
    loading: boolean,
    fetchData: () => void;
}


export const useAxios = ({ url, method, body = null, headers }: AxiosProps): AxiosResult => {

    useAxiosInterceptor();
    const [response, setResponse] = useState<any>(null);
    const [error, setError] = useState<string>('');
    const [loading, setloading] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    

    const fetchData = async () => {
        try{
            setloading(true);
            const data = await axiosInstance[method](url, {headers, data:body },{withCredentials: true})
            setSent(true);
            setResponse(data);
            
        } catch(e: any){
            setError(e.message);
        } finally {
            setloading(false);
        }
           
};

    useEffect(() => {
        if(!loading && sent === false){
            fetchData();
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [method, url, body, headers]);

    return { response, error, loading, fetchData };
};