import { Card } from "./Card"
import { CustomButton } from "./CustomButton"
import { Link } from "react-router-dom"
import { ProductType } from "./Products"

export const ProductCard = (props : ProductType) => {
  return (
    <Card className="border shadow-md relative border-[#C4C4C4] hover:outline py-4 hover:outline-primary overflow-hidden ">
        <div className="flex flex-col justify-between items-center relative">
          <div className="md:h-56 h-48">
            <img src={props.image.toString()} alt={props.name} className="md:h-48 h-40 mb-2"/>
          </div>
            <h5 className="text-xl font-bold mb-2">{props.name}</h5>
            <p className=" px-5 mb-3 md:leading-6 leading-5 text-justify min-[1408px]:h-[200px] min-[1148px]:h-[240px] min-[990px]:h-[300px] min-[768px]:h-[400px] sm:h-[230px]">{props.shortDescription && props.shortDescription.length > 300 ? props.shortDescription.slice(0,300) + '...' : props.shortDescription}</p>
            <Link to={`${props.slug}`}> 
                <CustomButton title="More info" className="mb-2"/>
            </Link>
        </div>
        {props.availability === 0 && <div className="bg-red-600 shadow-lg text-white px-7 -rotate-45 absolute top-8 -left-9">
          Not available
        </div>}
    </Card>
  )
}
