import { Specification } from "./Product.types"

type SpecTableProps = {
    specs: Specification[];
}

export const SpecificationTable = ({specs}: SpecTableProps) => {
  return (
    <div className="xl:px-32 lg:px-20 md:px-10 px-5 my-7">
        <h3 className="text-xl font-semibold mb-5">Specifications</h3>
        <section className=' '>
            {specs && specs.map((spec, index)=>{
                return (

                    <div className={`grid grid-cols-2 text-sm sm:text-base  p-1 font-medium ${index % 2 === 0 ? 'odd:bg-primary odd:bg-opacity-[0.15]' : '' } `} key={index}>
                        <span>{spec.key}</span>
                        <span>{spec.value}</span>
                    </div>
                    )
                })}
            
           
        </section>
    </div>
  )
}
