import  {  useState } from 'react';
import Breadcrumb from '../Components/Breadcrumb';
import { Blog } from './Admin/AdminBlog';
import axios from 'axios';
import { BlogCard } from '../Components/BlogCard';
import InfiniteScroll from 'react-infinite-scroller';
import { LoadingSpinner } from '../Components/LoadingSpinner';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';

export const Blogs = () => {
  const [data, setData] = useState<Blog[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);



  useSetPageTitle("Blogs");
  const fetchData = async (pageNumber: number) => {
    if (loading || loadingMore) return;
    setLoading(true);
    try {
      const response = await axios.get(`/api/posts?page=${pageNumber}&limit=3`);
      const responseData = response.data.posts;
      if (responseData.length === 0) {
        setHasMore(false);
      } else {
        setData((prevData) => prevData.concat(responseData));
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const loadMore = () => {
    if (hasMore) {
      setLoadingMore(true); 
      fetchData(page); 
    }
  };

  const renderData = () => {
    return data.map((blog) => <BlogCard key={blog.id} blog={blog} />);
  };

  return (
    <div className='xl:px-32 lg:px-20 md:px-10 px-5'>
      <div className='md:my-5 my-3'>
            <h2 className='font-bold text-xl md:text-2xl'>Blogs</h2>  
            <Breadcrumb />
        </div>
      <div>
        
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<LoadingSpinner key={0} />}
        >
          <div className='grid mt-5 gap-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1'>
            {data && renderData()}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};
