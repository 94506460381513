import React, { useState, useRef } from "react";
import { InputField } from "../Components/InputField";
import { CustomButton } from "../Components/CustomButton";
import ReCAPTCHA from 'react-google-recaptcha';
import Request from "../assets/requestQuote.webp";
import axios from "axios";
import { ErrorSpan } from "../Components/ErrorSpan";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSetPageTitle } from "../Hooks/useSetPageTitle";
import useFormValidation from "../Hooks/usePeptideFormValidation";

type Amino = {
  peptideSequence: string;
  quantityGm: number | null;
  quantityMg: number | null;
  purity: string;
};

type Quote = {
  name: string;
  mailingAddress: string;
  email: string;
  state: string;
  zip: number | null;
  country: string;
  companyName: string;
  phone: number | null;
  category: number | null;
  product: string;
  aminoAcids: Amino[];
};

export const QuoteAPeptide = () => {
  const sitekey:any = process.env.REACT_APP_CAPTCHA_KEY;
  useSetPageTitle("Quote a peptide");
  const formRef = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { errors, validateData, handleBlur, acidErrors, handleAminoAcidBlur, setAcidErrors, validateAmino } = useFormValidation();
  const [quoteDetails, setQuoteDetails] = useState<Quote>({
    name: "",
    mailingAddress: "",
    email: "",
    state: "",
    zip: null,
    country: "",
    companyName: "",
    phone: null,
    category: null,
    product: "",
    aminoAcids: [
      { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
      { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
      { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
    ],
  });
  
  const [captchaErr, setCaptchErr] = useState<string>();
  
  const [recaptcha , setRecaptcha] = useState<string>("");
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setQuoteDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const addAminoAcid = () => {
    setQuoteDetails((prevQuoteDetail) => ({
      ...prevQuoteDetail,
      aminoAcids: [
        ...prevQuoteDetail.aminoAcids,
        { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
      ],
    }));
    setAcidErrors((prevErrors: any) => ({
      ...prevErrors,
      errors: [
        ...prevErrors.errors,
        { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
      ],
    }));
  };

  const removeAminoAcid = (index: number) => {
    if (quoteDetails.aminoAcids.length > 3) {
      setQuoteDetails((prevQuoteDetail) => {
        const newAminoAcids = [...prevQuoteDetail.aminoAcids];
        newAminoAcids.splice(index, 1);
        return {
          ...prevQuoteDetail,
          aminoAcids: newAminoAcids,
        };
      });

      setAcidErrors((prevErrors: any) => {
        const newErrors = [...acidErrors.errors];
        newErrors.splice(index, 1);
        return {
          errors: newErrors,
        };
      });
    }
  };

  const handleAminoAcidChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    index: number,
    fieldName: string
  ) => {
    const { value } = event.target;
    setQuoteDetails((prevQuoteDetail) => {
      const updatedAminoAcids = [...prevQuoteDetail.aminoAcids];
      updatedAminoAcids[index] = {
        ...updatedAminoAcids[index],
        [fieldName]: value,
      };
      return { ...prevQuoteDetail, aminoAcids: updatedAminoAcids };
    });
  };
  
  const handleRecaptcha = (value:any)=>{
    setRecaptcha(value);
    setCaptchErr("");
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    

    let errorcheck = validateData(formData);
    let validAmino = validateAmino(quoteDetails.aminoAcids);
    formData.append("sequence", JSON.stringify(quoteDetails.aminoAcids));
    if(!recaptcha){
      setCaptchErr("Captcha is required");
      return;
    }
    formData.append("recaptcha", recaptcha);
    
    try {
      if (!errorcheck && !validAmino && recaptcha) {
        setLoading(true);
        await axios({
          method: "post",
          url: "/api/peptide-synthesis",
          data: formData,
        });
        formRef.current?.reset();
        captchaRef.current?.reset();
        setQuoteDetails((prev) => ({
          ...prev,
          aminoAcids: [
            {
              peptideSequence: "",
              purity: "",
              quantityGm: null,
              quantityMg: null,
            },
            {
              peptideSequence: "",
              purity: "",
              quantityGm: null,
              quantityMg: null,
            },
            {
              peptideSequence: "",
              purity: "",
              quantityGm: null,
              quantityMg: null,
            },
          ],
        }));
        setLoading(false);
        toast.success("Form submitted successfully");
      }

    } catch (e) {
      setLoading(false);
      toast.error("Error submitting form");
    }
  };

  return (
    <>
      <div>
        <div
          style={{ backgroundImage: `url(${Request})` }}
          className="xl:px-32 lg:px-20 md:px-10 px-5 py-14"
        >
          <h1 className="text-3xl font-bold text-white">Peptide Synthesis</h1>
          <p className="text-lg font-medium text-white">
            If you got any questions, please do not hesitate to send us a
            message. We reply within 24 hours  !
          </p>
        </div>
        <div className="xl:px-32 lg:px-20 md:px-10 px-5">
          <form
            ref={formRef}
            className="  bg-primary bg-opacity-[0.15] p-5 md:p-10 my-10 items-center"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-7">
              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  className={`${
                    errors.name ? " border-[1px] border-red-500" : ""
                  }`}
                  placeholder="Name *"
                  fieldName="name"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.name && <ErrorSpan error={errors.name} />}
              </div>
              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="Mailing Adress *"
                  className={`${
                    errors.mailingAddress ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="mailingAddress"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.mailingAddress && (
                  <ErrorSpan error={errors.mailingAddress} />
                )}
              </div>

              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="City *"
                  className={`${
                    errors.city ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="city"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.city && <ErrorSpan error={errors.city} />}
              </div>
              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="Email *"
                  className={`${
                    errors.email ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="email"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.email && <ErrorSpan error={errors.email} />}
              </div>
              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="State *"
                  className={`${
                    errors.state ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="state"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.state && <ErrorSpan error={errors.state} />}
              </div>

              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="Zip *"
                  className={`${
                    errors.zip ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="zip"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.zip && <ErrorSpan error={errors.zip} />}
              </div>
              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="Country *"
                  className={`${
                    errors.country ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="country"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.country && <ErrorSpan error={errors.country} />}
              </div>
              <div className="flex flex-col relative">
                <InputField
                  type="text"
                  placeholder="Company Name *"
                  className={`${
                    errors.company ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="company"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.company && <ErrorSpan error={errors.company} />}
              </div>
              <div className="flex flex-col relative">
                <InputField
                  type="phone"
                  placeholder="Phone *"
                  className={`${
                    errors.phone ? " border-[1px] border-red-500" : ""
                  }`}
                  fieldName="phone"
                  onBlur={(e) => handleBlur(e)}
                  onChange={handleChange}
                />
                {errors.phone && <ErrorSpan error={errors.phone} />}
              </div>

              <div className="flex flex-col relative">
                <div className="flex gap-2 items-center mt-2">
                  <span className="font-medium">Document:</span>
                  <input type="file" accept="application/pdf" onBlur={handleBlur} name="document" />
                </div>
                {errors.document && <ErrorSpan error={errors.document} />}
              </div>
              <div className="flex flex-col relative">
                <textarea
                  name="message"
                  rows={3}
                  placeholder="Message"
                  className="p-2"
                ></textarea>
                {errors.message && <ErrorSpan error={errors.message}/>}
              </div>
            </div>
            <h4 className="font-semibold text-lg my-5 px-1">
              Choose Your Amino Acids
            </h4>

            <div className="flex  flex-col gap-7 mb-5">
              {quoteDetails.aminoAcids &&
                quoteDetails.aminoAcids.map((quoteDetail, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-between items-center gap-5"
                  >
                    <div className="flex flex-col sm:col-span-2 md:col-span-4 relative">
                      <InputField
                        type="text"
                        placeholder="Peptide Sequence *"
                        onBlur={(e) =>
                          handleAminoAcidBlur(e, index, "peptideSequence")
                        }
                        className={` ${
                          acidErrors.errors[index].peptideSequence
                            ? " border-[1px] border-red-500"
                            : ""
                        }`}
                        value={quoteDetail.peptideSequence}
                        onChange={(e) =>
                          handleAminoAcidChange(e, index, "peptideSequence")
                        }
                        fieldName="peptideSequence"
                      />
                      {quoteDetail.peptideSequence && (
                        <span className="ms-2 text-[0.93rem]">
                          Sequence Characters:{" "}
                          {quoteDetail.peptideSequence.length}
                        </span>
                      )}
                      {acidErrors.errors &&
                        acidErrors.errors[index] &&
                        acidErrors.errors[index].peptideSequence && ( // Display error if present
                          <ErrorSpan
                            error={acidErrors.errors[index].peptideSequence}
                          />
                        )}
                    </div>
                    <div className="flex flex-col relative">
                      <InputField
                        type="text"
                        placeholder="Desired Quantity(Mg) *"
                        fieldName="quantityMg"
                        onChange={(e) =>
                          handleAminoAcidChange(e, index, "quantityMg")
                        }
                        className={`${
                          acidErrors.errors[index].quantityMg
                            ? " border-[1px] border-red-500"
                            : ""
                        }`}
                        value={
                          quoteDetail.quantityMg !== null
                            ? quoteDetail.quantityMg
                            : ""
                        }
                        onBlur={(e) =>
                          handleAminoAcidBlur(e, index, "quantityMg")
                        }
                      />
                      {acidErrors.errors &&
                        acidErrors.errors[index] &&
                        acidErrors.errors[index].quantityMg && ( // Display error if present
                          <ErrorSpan
                            error={acidErrors.errors[index].quantityMg}
                          />
                        )}
                    </div>
                    <div className="flex flex-col relative">
                      <InputField
                        type="text"
                        placeholder="Desired Quantity(Gm) *"
                        fieldName="quantityGm"
                        onChange={(e) =>
                          handleAminoAcidChange(e, index, "quantityGm")
                        }
                        className={`${
                          acidErrors.errors[index].quantityGm
                            ? " border-[1px] border-red-500"
                            : ""
                        }`}
                        value={
                          quoteDetail.quantityGm !== null
                            ? quoteDetail.quantityGm
                            : ""
                        }
                        onBlur={(e) =>
                          handleAminoAcidBlur(e, index, "quantityGm")
                        }
                      />
                      {acidErrors.errors &&
                        acidErrors.errors[index] &&
                        acidErrors.errors[index].quantityGm && ( // Display error if present
                          <ErrorSpan
                            error={acidErrors.errors[index].quantityGm}
                          />
                        )}
                    </div>

                    <div className="flex flex-col relative">
                      <select
                        className={`outline-none px-1 py-3 ${
                          acidErrors.errors[index].purity
                            ? " border-[1px] border-red-500"
                            : ""
                        }`}
                        name="purity"
                        value={quoteDetail.purity}
                        onChange={(e) =>
                          handleAminoAcidChange(e, index, "purity")
                        }
                        onBlur={(e) => handleAminoAcidBlur(e, index, "purity")}
                      >
                        <option value="">Purity *</option>
                        <option value="70">Crude desalted(Purit ~70%)</option>
                        <option value="80"> &gt; 80% pure</option>
                        <option value="90">&gt; 90% pure</option>
                        <option value="95">&gt; 95% pure</option>
                        <option value="97">&gt; 97% pure</option>
                      </select>
                      {acidErrors.errors &&
                        acidErrors.errors[index] &&
                        acidErrors.errors[index].purity && (
                          <ErrorSpan error={acidErrors.errors[index].purity} />
                        )}
                    </div>

                    <button
                      className="bg-primary text-white px-3 py-2 h-12"
                      title="Remove"
                      type="button"
                      onClick={() => {
                        removeAminoAcid(index);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              <CustomButton  title="Add Sequence" onClick={addAminoAcid} />
              <div className="flex flex-col relative">
                <ReCAPTCHA className="captcha" sitekey={sitekey}  onChange={handleRecaptcha} ref={captchaRef}/>
                {captchaErr && <ErrorSpan error={captchaErr} />}
              </div>
            </div>
            <CustomButton type="submit" loading={loading} title="Submit" className="w-[50%]" />
          </form>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </>
  );
};
