import  SpecialityImg  from "../assets/Speciality.webp";
import { Card } from "./Card"
import Efficiency from "../assets/clock 1.webp"
import Volume from "../assets/save-energy.webp"
export const Speciality = () => {
  return (
    <div style={{backgroundImage: `url(${SpecialityImg})` , backgroundRepeat: "repeat-y"}} className="flex flex-col justify-center items-center px-5 md:px-10 xl:px-72 lg:px-40">
        <h1 className="text-center md:text-4xl text-3xl font-bold mt-8 text-white">Our Speaciality</h1>
        <div className='flex flex-col md:flex-row md:gap-32 gap-20 mt-10 mb-16 justify-items-center items-center'>
            <Card>
                <div className="flex flex-col justify-start items-start gap-5 px-8 py-8">
                    <img src={Efficiency} alt="efficiency" className=""/>
                    <h4 className="font-bold text-xl">Efficiency</h4>
                    <p className="font-medium">
                    Waste minimization is a key advantage of Discovery-4. Unique design, that eliminates unnecessary washes, cuts waste generation by 60%.
                    </p>
                </div>
            </Card>
            <Card>
                <div className="flex flex-col justify-start items-start gap-5 px-8 py-8">
                    <img src={Volume} alt="volume" />
                    <h4 className="font-bold text-xl">Volume</h4>
                    <p className="font-medium">
                    With twelve reactors as large as 100 ml each, Discovery-12 produces uniform, high quality peptides from reactor to reactor and run to run.
                    </p>
                </div>
            </Card>
        </div>
    </div>
  )
}
