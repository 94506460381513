import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { Home } from './Pages/Home';
import { Navbar } from './Components/Navbar';
import { Footer } from './Components/Footer';
import { HeaderStrip } from './Components/HeaderStrip';
import { ProductList } from './Pages/ProductList';
import { ProductDetails } from './Pages/ProductDetails';
import { AdminHome } from './Pages/Admin/AdminHome';
import { ProductForm } from './Pages/Admin/ProductForm';
import { RequestAQuote } from './Pages/RequestAQuote';
import { PartsAndConsumable } from './Pages/PartsAndConsumable';
import ScrollToTop from './Components/ScrollToTop';
import { useEffect, useRef, useState } from 'react';
import { QuoteAPeptide } from './Pages/QuoteAPeptide';
import { AdminQuotes } from './Pages/Admin/AdminQuotes';
import { AdminPeptideForm } from './Pages/Admin/AdminPeptideForm';
import { AminoAcidProducts } from './Pages/AminoAcidProducts';
import { ReagentsProducts } from './Pages/ReagentsProducts';
import { ResinsProduct } from './Pages/ResinsProduct';
import { AdminLogin } from './Pages/Admin/AdminLogin';
import { AdminNavbar } from './Components/AdminNavbar';
import {  useAuthContext } from './Hooks/useAuthContext';
import { AdminPartsAndConsumables } from './Pages/Admin/AdminPartsAndConsumables';
import { CreatePart } from './Pages/Admin/CreatePart';
import { CreateBlog } from './Pages/Admin/CreateBlog';
import { AdminBlog } from './Pages/Admin/AdminBlog';
import { Blogs } from './Pages/Blogs';
import { BlogDetail } from './Pages/BlogDetail';
import { ErrorPage } from './Pages/ErrorPage';
import { AboutUsPage } from './Pages/AboutUsPage';
import { Unauthorized } from './Pages/Unauthorized';
import { OtherProductDetails } from './Pages/OtherProductDetails';
import { AdminMessages } from './Pages/Admin/AdminMessages';



function App() {
  const [admin, setAdmin] = useState<any>();
  const {user} = useAuthContext();

  const faqRef = useRef<HTMLDivElement>(null);
  const productRef = useRef<HTMLDivElement>(null);

  const scrollToFaq = () => {
    faqRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToProduct = () => {
    productRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    setAdmin(localStorage.getItem("user"));
  }, [admin]);
  return (
    <>  
    <BrowserRouter>
    <ScrollToTop/>
      <HeaderStrip scrollToFaq={scrollToFaq} />   
      <Navbar/> 
      <AdminNavbar/>  
      <Routes>
        <Route index element={<Home ref={faqRef} scrollToProducts={scrollToProduct} productRef={productRef} />} />
        <Route path='peptide-synthesizers' element={<ProductList />} />
        <Route path='peptide-synthesizers/:id' element={<ProductDetails />} />
        <Route path='request-quote' element={<RequestAQuote />} />
        <Route path='quote-a-peptide' element={<QuoteAPeptide />} />
        <Route path='parts-and-consumables' element={<PartsAndConsumable />} />
        <Route path='amino-acids' element={<AminoAcidProducts />} />
        <Route path='reagents-and-linkers' element={<ReagentsProducts />} />
        <Route path='resins' element={<ResinsProduct />} />
        <Route path='blogs' element={<Blogs />} />
        <Route path='blog/:id' element={<BlogDetail />} />
        <Route path='about-us' element={<AboutUsPage />} />
        <Route path='amino-acids/:name' element={<OtherProductDetails Category="Amino Acids" />} />
        <Route path='reagents-and-linkers/:name' element={<OtherProductDetails Category="Reagents & Linkers" />} />
        <Route path='resins/:name' element={<OtherProductDetails Category="Resins" />} />
      

        {/* ADMIN ROUTES */}
        <Route path='admin/login' element={!user.user ?  <AdminLogin /> : <Navigate to="/admin/products" />} />
        <Route path='admin/products' element={user.user ? <AdminHome  /> : <Navigate to="/admin/login" />} />
        <Route path='admin/edit-product/:id' element={user.user ? <ProductForm   /> : <Navigate to="/admin/login" />} />
        <Route path='admin/create-product' element={user.user ? <ProductForm   /> : <Navigate to="/admin/login" />} />
        <Route path='admin/quotes' element={user.user ? <AdminQuotes   /> : <Navigate to="/admin/login" />} />
        <Route path='admin/peptide-quotes' element={user.user ? <AdminPeptideForm /> : <Navigate to="/admin/login" />} />
        <Route path='admin/parts-and-consumables' element={user.user ? <AdminPartsAndConsumables /> : <Navigate to="/admin/login" />} />
        <Route path='admin/create-part' element={user.user ? <CreatePart /> : <Navigate to="/admin/login" />} />
        <Route path='admin/edit-part/:id' element={user.user ? <CreatePart /> : <Navigate to="/admin/login" />} />
        <Route path='admin/edit-part/:id' element={user.user ? <CreatePart /> : <Navigate to="/admin/login" />} />
        <Route path='admin/blog' element={user.user ? <AdminBlog /> : <Navigate to="/admin/login" />} />
        <Route path='admin/create-blog' element={user.user ? <CreateBlog /> : <Navigate to="/admin/login" />} />
        <Route path='admin/edit-blog/:id' element={user.user ? <CreateBlog /> : <Navigate to="/admin/login" />} />
        <Route path='admin/messages' element={user.user ? <AdminMessages /> : <Navigate to="/admin/login" />} />
        <Route path='admin/unauthorized' element={<Unauthorized />} />

        <Route path='/error' element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
      <Footer />
    </BrowserRouter>  
    </>
  );
} 

export default App;
