import { AboutUs } from '../Components/AboutUs';
import { AminoAcids } from '../Components/AminoAcids';
import { HeroCarousel } from '../Components/HeroCarousel';
import { Products } from '../Components/Products';
import { QuotePeptide } from '../Components/QuotePeptide';
import { Speciality } from '../Components/Speciality';
import { Testimonials } from '../Components/Testimonials';
import { Blogs } from '../Components/Blogs';
import { Faq } from '../Components/Faq';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';
import { forwardRef } from 'react';

interface HomeProps {
  // Define any props your component might need
  ref: any,
  productRef: any,
  scrollToProducts: () => void;
}


export const Home = forwardRef<HTMLDivElement, HomeProps>((props, ref) => {
  useSetPageTitle("Home");
  return (
    <div>
        <HeroCarousel scrollToProducts={props.scrollToProducts}/>
        <Products ref={props.productRef} />
        <AminoAcids />
        <QuotePeptide />
        <Speciality />
        <AboutUs />
        <Testimonials />
        <Blogs />
        <Faq  ref={ref}/>
    </div>
  )
})
