import React from 'react';

type InputProps = {
  type: string;
  placeholder: string;
  fieldName: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | undefined ;
  onBlur?: (e: any) => void | ((e: any, index: number, field: string) => void);
  className?: string;
  accept?: string;
};

export const InputField = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, placeholder, fieldName, onChange, value, onBlur, className, accept }: InputProps, ref) => {
    return (
      <input
        type={type}
        placeholder={placeholder}
        ref={ref}
        name={fieldName}
        value={value}
        className={`placeholder:text-grey outline-none py-3 px-3 h-10 md:h-12 ${className}`}
        onBlur={onBlur}
        onChange={onChange}
        accept={accept}
      />
    );
  }
);
