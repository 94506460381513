import { ProductType } from "./Products";
import { CustomButton } from "./CustomButton";
import { Link } from "react-router-dom";

type OtherProductProps = {
  product: ProductType;
  route: string;
};

export const OtherProductsCard = ({ product, route }: OtherProductProps) => {
  return (
    <div className="border-[#C4C4C4] relative border shadow-md flex flex-col overflow-hidden py-5 justify-center items-center gap-2 hover:outline hover:outline-primary ">
      <div className="md:h-56 h-48">
        <img
          className='md:h-48 h-40 mb-2"'
          src={product.image.toString()}
          alt={product.name}
        />
      </div>
      <h4 className="text-xl font-bold">{product.name}</h4>
      <span className="font-medium">
        CAS #: <span>{product.casNo}</span>
      </span>
      <span className="font-medium">
        Catalog number: <span>{product.catalogNo}</span>
      </span>

      <div className="mt-3">
        <Link to={`/${route}/${product.slug}`}>
          <CustomButton title="More Info" />
        </Link>
      </div>
      {product.availability === 0 && <div className="bg-red-600 font-normal shadow-lg text-white px-7 -rotate-45 absolute top-8 -left-9">
          Not available
        </div>}
    </div>
  );
};
