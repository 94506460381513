import React, { useState } from 'react';

interface AccordionProps {
  index: number;
  question: string;
  answer: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ index, question, answer }) => {
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

  const toggleAccordion = () => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='bg-white px-3'>
      <h5 id={`accordion-flush-heading-${index}`}>
        <button
          type="button"
          className={`flex items-center justify-between w-full py-5 font-medium rtl:text-right text-black border-b border-gray-200 gap-3`}
          data-accordion-target={`#accordion-flush-body-${index}`}
          aria-expanded={activeAccordion === index}
          aria-controls={`accordion-flush-body-${index}`}
          onClick={toggleAccordion}
        >
          <span className='text-start'>{question}</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 rotate-180 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
          </svg>
        </button>
      </h5>
      <div
        id={`accordion-flush-body-${index}`}
        className={`py-5  border-gray-200 dark:border-gray-700 ${activeAccordion === index ? '' : 'hidden'}`}
        aria-labelledby={`accordion-flush-heading-${index}`}
      >
        {answer}
      </div>
    </div>
  );
};

const FAQAccordian: React.FC = () => {
  return (
    <div id="accordion-container" className=''>
      <Accordion
        index={0}
        question="What is Flowbite?"
        answer={
          <div>
            <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa corporis dolor amet vero? Quidem nisi quaerat illum! Cupiditate quis sit magnam facilis? Fugiat, rem rerum.</p>
          </div>
        }
      />
      <Accordion
        index={2}
        question="Is there a Figma file available?"
        answer={
          <div>
           <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa corporis dolor amet vero? Quidem nisi quaerat illum! Cupiditate quis sit magnam facilis? Fugiat, rem rerum.</p>
          </div>
        }
      />
      <Accordion
        index={3}
        question="What are the differences between Flowbite and Tailwind UI?"
        answer={
          <div>
            <p className='text'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa corporis dolor amet vero? Quidem nisi quaerat illum! Cupiditate quis sit magnam facilis? Fugiat, rem rerum.</p>
          </div>
        }
      />
    </div>
  );
};

export default FAQAccordian;
