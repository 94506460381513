import { useState } from 'react';
import {
    nameReg,
    mailingAddressReg,
    cityReg,
    stateReg,
    countryReg,
    companyReg,
    phoneReg,
    emailReg,
    zipReg,
    pepetidSeqRegex,
    quantityReg,
    msgReg,
  } from "../regex";


const useFormValidation = () => {
  const [errors, setErrors] = useState<any>({});
  const [acidErrors, setAcidErrors] = useState<any>({
    errors: [
      { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
      { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
      { peptideSequence: "", purity: "", quantityGm: null, quantityMg: null },
    ],
  });

  const requiredErr = "This field is required";
  const validateData = (formData: FormData) => {
    const file = formData.get("document") as File;
    const tempErr = {
      phone:
        formData.get("phone") === ""
          ? requiredErr
          : !phoneReg.test(formData.get("phone")?.toString().trim() || "")
          ? "Enter Valid number"
          : "",

      name:
        formData.get("name") === ""
          ? requiredErr
          : !nameReg.test(formData.get("name")?.toString().trim() || "")
          ? "Enter Valid Name"
          : "",

      city:
        formData.get("city") === ""
          ? requiredErr
          : !cityReg.test(formData.get("city")?.toString().trim() || "")
          ? "Enter Valid City Name"
          : "",

      email:
        formData.get("email") === ""
          ? requiredErr
          : !emailReg.test(formData.get("email")?.toString().trim() || "")
          ? "Enter Valid Email"
          : "",

      state:
        formData.get("state") === ""
          ? requiredErr
          : !stateReg.test(formData.get("state")?.toString().trim() || "")
          ? "Enter Valid State Name"
          : "",

      zip:
        formData.get("zip") === ""
          ? requiredErr
          : !zipReg.test(formData.get("zip")?.toString().trim() || "")
          ? "Enter Valid ZipCode"
          : "",

      country:
        formData.get("country") === ""
          ? requiredErr
          : !countryReg.test(formData.get("country")?.toString().trim() || "")
          ? "Enter Valid Country Name"
          : "",

      company:
        formData.get("company") === ""
          ? requiredErr
          : !companyReg.test(formData.get("company")?.toString().trim() || "")
          ? "Enter Valid Company Name"
          : "",

      mailingAddress:
        formData.get("mailingAddress") === ""
          ? requiredErr
          : !mailingAddressReg.test(
              formData.get("mailingAddress")?.toString().trim() || ""
            )
          ? "Enter Valid Mailing Address"
          : "",

      document:
        file.name !== "" && file.type !== "application/pdf"
          ? "Only PDF Files are allowed"
          : "",

      message: !msgReg.test(formData.get("message")?.toString().trim() || "")
        ? "Enter Valid Message"
        : "",

    };

    setErrors(tempErr);
    const errorCheck = Object.values(tempErr).some((error) => error !== "");

    return errorCheck;
  };

  const handleBlur = (event:any) => {
    const { name, value } = event.target;
    let errorMessage = "";
    switch (name) {
      case "name":
        errorMessage =
          value === ""
            ? requiredErr
            : !nameReg.test(value.trim())
            ? "Enter Valid Name"
            : "";
        break;
      case "mailingAddress":
        errorMessage =
          value === ""
            ? requiredErr
            : !mailingAddressReg.test(value.trim())
            ? "Enter Valid Mailing Address"
            : "";
        break;
      case "city":
        errorMessage =
          value === ""
            ? requiredErr
            : !cityReg.test(value.trim())
            ? "Enter Valid City Name"
            : "";
        break;
      case "email":
        errorMessage =
          value === ""
            ? requiredErr
            : !emailReg.test(value.trim())
            ? "Enter Valid Email"
            : "";
        break;
      case "state":
        errorMessage =
          value === ""
            ? requiredErr
            : !stateReg.test(value.trim())
            ? "Enter Valid State Name"
            : "";
        break;
      case "zip":
        errorMessage =
          value === ""
            ? requiredErr
            : !zipReg.test(value.trim())
            ? "Enter Valid ZipCode"
            : "";
        break;
      case "country":
        errorMessage =
          value === ""
            ? requiredErr
            : !countryReg.test(value.trim())
            ? "Enter Valid Country Name"
            : "";
        break;
      case "company":
        errorMessage =
          value === ""
            ? requiredErr
            : !companyReg.test(value.trim())
            ? "Enter Valid Company Name"
            : "";
        break;
      case "phone":
        errorMessage =
          value === ""
            ? requiredErr
            : !phoneReg.test(value.trim())
            ? "Enter Valid number"
            : "";
        break;
      case "document":
        const file = event.target.files;
        if (file && file[0]) {
          errorMessage =
            file[0].type !== "application/pdf"
              ? "Only PDF Files are allowed"
              : "";
        }
        break;
      default:
        break;
    }

    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleAminoAcidBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    field: string
  ) => {
    const { value } = e.target;
    setAcidErrors((prevErrors: any) => ({
      ...prevErrors,
      errors: prevErrors.errors.map((acidErr: any, i: number) => {
        if (i === index) {
          return {
            ...acidErr,
            [field]: validateAminoAcidField(field, value),
          };
        }
        return acidErr;
      }),
    }));
  };

  const validateAmino = (aminoAcids: any[]) => {
    let hasErrors = false;
    aminoAcids.map((amino, index) => {
      if (amino.peptideSequence === "") {
        hasErrors = true;
        setAcidErrors((prevErros: any) => ({
          ...prevErros,
          acidErrors: [
            ...acidErrors.errors,
            (acidErrors.errors[index].peptideSequence = requiredErr),
          ],
        }));
      } else {
        if (!pepetidSeqRegex.test(amino.peptideSequence)) {
          hasErrors = true;
          setAcidErrors((prevErros: any) => ({
            ...prevErros,
            acidErrors: [
              ...acidErrors.errors,
              (acidErrors.errors[index].peptideSequence =
                "Invalid  Sequence Name"),
            ],
          }));
        }
      }

      if (amino.quantityGm === null) {
        hasErrors = true;
        setAcidErrors((prevErros: any) => ({
          ...prevErros,
          acidErrors: [
            ...acidErrors.errors,
            (acidErrors.errors[index].quantityGm = requiredErr),
          ],
        }));
      } else {
        if (!quantityReg.test(amino.quantityGm.toString())) {
          hasErrors = true;
          setAcidErrors((prevErros: any) => ({
            ...prevErros,
            acidErrors: [
              ...acidErrors.errors,
              (acidErrors.errors[index].quantityGm =
                "Quantity should be numeric"),
            ],
          }));
        }
      }

      if (amino.quantityMg === null) {
        hasErrors = true;
        setAcidErrors((prevErros: any) => ({
          ...prevErros,
          acidErrors: [
            ...acidErrors.errors,
            (acidErrors.errors[index].quantityMg = requiredErr),
          ],
        }));
      } else {
        if (!quantityReg.test(amino.quantityMg.toString())) {
          hasErrors = true;
          setAcidErrors((prevErros: any) => ({
            ...prevErros,
            acidErrors: [
              ...acidErrors.errors,
              (acidErrors.errors[index].quantityMg =
                "Quantity should be numeric"),
            ],
          }));
        }
      }
      if (amino.purity === "") {
        hasErrors = true;
        setAcidErrors((prevErros: any) => ({
          ...prevErros,
          acidErrors: [
            ...acidErrors.errors,
            (acidErrors.errors[index].purity = requiredErr),
          ],
        }));
      }

      return ''
    });

    return hasErrors;
  };

  const validateAminoAcidField = (field: string, value: string) => {
    switch (field) {
      case "peptideSequence":
        return value.trim() === ""
          ? requiredErr
          : !pepetidSeqRegex.test(value.trim())
          ? "Invalid  Sequence Name"
          : "";
      case "quantityMg":
        return value.trim() === ""
          ? requiredErr
          : !quantityReg.test(value.trim())
          ? "Quantity should be numeric"
          : "";
      case "quantityGm":
        return value.trim() === ""
          ? requiredErr
          : !quantityReg.test(value.trim())
          ? "Quantity should be numeric"
          : "";
      case "purity":
        return value === "" ? requiredErr : "";
      default:
        return "";
    }
  };

  return { errors, validateData, handleBlur, acidErrors, handleAminoAcidBlur, validateAminoAcidField, setAcidErrors, validateAmino };
};

export default useFormValidation;
