import  { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Blog } from './Admin/AdminBlog';
import { useAxios } from '../Hooks/useAxios';
import { LoadingSpinner } from '../Components/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons'

export const BlogDetail = () => {
    const {id} = useParams();
    const [blog, setBlog] = useState<Blog>();
  
  const {response} = useAxios({
      method: 'get',
      url: `/api/posts/${id}`
  })
  
  useEffect(()=>{
    if(response != null){
      setBlog(response.data.post)
      document.title = `${response.data.post.title} - Peptide Machines`
    }
  },[response]) 


  return (
    <div className='xl:px-32 lg:px-20 md:px-10 px-5'>
      {!blog && <LoadingSpinner />}
        {blog && 
         <div className='flex flex-col gap-4'>
          <div>
            <h2 className='font-bold text-2xl mt-4'>{blog.title}</h2> 
            <span className='text-gray-600'>{blog.createdAt && new Date(blog.createdAt).toLocaleDateString('en-US', {  year: 'numeric', month: 'long', day: 'numeric' })}</span>
            <p><FontAwesomeIcon icon={faEye}/><span className='ms-2'>{blog.readCount ? blog.readCount : 0}</span></p>
          </div>
         <div className='flex flex-col gap-4'>
            <img loading='lazy' src={blog.image.toString()} alt='' className='w-[100%] h-96 object-cover'></img>
            <div className='flex gap-3'>
            {blog.tags && blog.tags.map((tag, index)=>{
              return (
                <div key={index} id={index.toString()} className="flex items-center max-w-fit bg-primary b text-white  py-1 px-2">
                  <span className="me-1">{tag}</span>
                </div>
              )
            })}
            </div>
            <article className='ql-editor mb-10' dangerouslySetInnerHTML={ { __html: blog.description }}></article>
         </div>
         </div>
        }
    </div>
  )
}
