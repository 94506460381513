import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "../../Components/Modal";
import {
  faFile,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  axiosInstance,
  useAxiosInterceptor,
} from "../../Interceptors/authInterceptor";
import { ExportToExcel } from "../../Components/ExportToExcel";
import { LoadingSpinner } from "../../Components/LoadingSpinner";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

export type Quote = {
  _id: string;
  name: string;
  mailingAddress: string;
  city: string;
  email: string;
  state: string;
  zip: string;
  country: string;
  company: string;
  phone: string;
  product: [
    {
      _id: string;
      name: string;
    }
  ];
};

export const AdminQuotes = () => {
  useAxiosInterceptor();
  const [quotes, setQuotes] = useState<Quote[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);
  const [quoteDetail, setQuoteDetail] = useState<Quote | null>();
  const {user } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user.user?.accessibility[4]) {
      navigate('/admin/unauthorized');
    }
  }, [user, navigate]);

  const fetchProducts = async (page: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/quotes?page=${page}&limit=10`,
        {
          withCredentials: true,
        }
      );
      const { quotes, lastPage } = response.data;
      setQuotes(quotes);
      setTotalPages(lastPage);
    } catch (error:any) {
      if(error.status === 403){
        navigate('/admin/blog')
      }
    }
  };

  useEffect(() => {
    if (!showModal) {
      setQuoteDetail(null);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    fetchProducts(currentPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleViewQuote = async (id: string) => {
    setShowModal(true);
    const { data } = await axiosInstance.get(`/api/quotes/${id}`, {
      withCredentials: true,
    });
    setQuoteDetail(data.quote);
  };

  

  return (
    <>
      <div className="lg:px-40 mt-7">
        <div className="flex justify-end my-1">
          <ExportToExcel  name="Quotes" />
        </div>
        {!quotes && <LoadingSpinner />}
        {quotes && <table className="table-fixed w-[100%] border-collapse mb-10">
          <thead className="">
            <tr className="text-[1.2rem] ">
              <th className="py-3">Name</th>
              <th className="py-3">Company</th>
              {/* <th className='py-3'>Products</th> */}
              <th className="py-3">Phone</th>
              <th className="py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {quotes &&
              quotes.map((quote: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className="text-center text-[1.1rem] font-medium  py-20 odd:bg-primary odd:bg-opacity-[0.15]"
                  >
                    <td className="py-2 ps-1">{quote.name}</td>
                    <td>{quote.company}</td>
                    {/* <td>{quote.product[0].name}</td> */}
                    <td>{quote.phone}</td>
                    <td className="flex gap-5 justify-center items-center py-2 text-primary text-2xl">
                      <button
                        onClick={() => {
                          handleViewQuote(quote._id);
                        }}
                      >
                        <FontAwesomeIcon icon={faFile} />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>}
        <div className="flex justify-center items-center gap-3 mt-5 text-xl mb-4">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div>
            Page{" "}
            <input
              className="w-10 outline-none text-center"
              type="number"
              value={currentPage}
              onChange={(e) => setCurrentPage(Number(e.target.value))}
            />{" "}
            <span className="me-2">Of</span> {totalPages}
          </div>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal}>
          <div className="flex flex-col ">
            <div
              className={`grid grid-cols-2 py-3 font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">Name:</strong>
              <span>{quoteDetail?.name}</span>
            </div>
            <div className={`grid grid-cols-2 py-3  font-medium `}>
              <strong className="px-10">Mailing Address:</strong>
              <span>{quoteDetail?.mailingAddress} </span>
            </div>
            <div
              className={`grid grid-cols-2 py-3  font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">City:</strong>
              <span>{quoteDetail?.city}</span>
            </div>
            <div className={`grid grid-cols-2 py-3  font-medium`}>
              <strong className="px-10">Email:</strong>
              <span>{quoteDetail?.email}</span>
            </div>
            <div
              className={`grid grid-cols-2 py-3  font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">State:</strong>
              <span>{quoteDetail?.state}</span>
            </div>
            <div className={`grid grid-cols-2 py-3  font-medium`}>
              <strong className="px-10">Zip:</strong>
              <span>{quoteDetail?.zip}</span>
            </div>
            <div
              className={`grid grid-cols-2 py-3  font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">Company:</strong>
              <span>{quoteDetail?.company}</span>
            </div>
            <div className={`grid grid-cols-2 py-3  font-medium`}>
              <strong className="px-10">Phone:</strong>
              <span>{quoteDetail?.phone}</span>
            </div>
            <div
              className={`grid grid-cols-2 py-3  font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">Product:</strong>
              <span>{quoteDetail?.product[0].name}</span>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
