import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import QuillEditor, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { InputField } from "../../Components/InputField";
import { CustomButton } from "../../Components/CustomButton";
import {
  axiosInstance,
  useAxiosInterceptor,
} from "../../Interceptors/authInterceptor";
import { useNavigate, useParams } from "react-router-dom";
import { Blog } from "./AdminBlog";
import { blogTitleRegex } from "../../regex";
import { ErrorSpan } from "../../Components/ErrorSpan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleXmark} from '@fortawesome/free-regular-svg-icons'
import { useAuthContext } from "../../Hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";


export const CreateBlog: React.FC = () => {
  useAxiosInterceptor();
  const [loading, setLoading] = useState<boolean>(false);
  const [draftLoading, setDraftLoading] = useState<boolean>(false);

  const {user} =  useAuthContext(); 
  const navigate= useNavigate();
  useEffect(() => {
    if (!user.user?.accessibility[2]) {
      navigate('/admin/unauthorized');
    }
  }, [user, navigate]);
  const [value, setValue] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [blog, setBlog] = useState<Blog>({
    id: "",
    image: "",
    description: "",
    title: "",
    tags: [],
    thumbnail: "",
    createdAt: null,
    updatedAt: null,
    slug: "",
    readCount: 0,
  });
  const [errors, setErrors] = useState<any>();
  const [imagePreview, setImagePreview] = useState<any>();
  const quill = useRef<ReactQuill>(null);
  const { id } = useParams();
  useEffect(() => {
    const getBlog = async () => {
      try {
        const blog = await axiosInstance.get(
          `api/admin/posts/${id?.toString()}`,
          {
            withCredentials: true,
          }
        );
        const fetchedBlog = blog.data.post;
        setBlog(fetchedBlog);
        setValue(fetchedBlog.description);
        setImagePreview(fetchedBlog.image);
        setTags(fetchedBlog.tags);
      } catch (error) {
        console.error("Error fetching part:", error);
      }
    };

    if (id) {
      getBlog();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const Font = Quill.import("formats/font");
  Font.whitelist = [
    "Arial",
    "Georgia",
    "Impact",
    "Tahoma",
    "Verdana",
    "Montserrat",
  ];
  Quill.register(Font, true);


  

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files![0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageUrl = reader.result as string;
        const quillEditor = quill.current!.getEditor();

        const range = quillEditor.getSelection(true);
        if (range !== null) {
          quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
        }
      };

      reader.readAsDataURL(file);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [{ background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ align: [] }], 
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler]// eslint-disable-line react-hooks/exhaustive-deps
  );

  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent", 
    "link",
    "image",
    "align",
    "color",
    "background", 
    "clean",
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBlog((prevBlog: Blog) => ({
      ...prevBlog,
      [name]: value,
    }));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyDown = (event: any)=>{
    const val = event.target.value;
    if(event.key === 'Enter'){
      setTags([...tags, val]);
      event.target.value = "";
    }
  }

  const handleTagRemove = (e: React.MouseEvent<SVGSVGElement>) => {
    const parent = e.currentTarget.parentNode as HTMLElement;
    const id = parseInt(parent.getAttribute('id') || '0', 10); 
    const updatedTags = [...tags]; 
    updatedTags.splice(id, 1); 
    setTags(updatedTags); 
  }
  

  const handleSubmit = async (isPublish: string) => {
    const form = document.getElementById("editorValue") as HTMLFormElement;
    const formData = new FormData(form);
    const hasErrors = validate(formData);
  
    if(!hasErrors){
      
      formData.append("description", value);
  
      for(let i=0;i< tags.length ;i++){
        formData.append('tags[]', tags[i]);
      }
  
      if (isPublish === "1") {
        setLoading(true)
        formData.append("publish", isPublish);
      } else {
        setDraftLoading(true);
      }
  
      const method = id ? "PUT" : "POST";
      const url = id ? `/api/posts/${id}` : `api/posts`;
  
      try {
        await axiosInstance({
          method: method,
          url: url,
          withCredentials: true,
          data: formData,
        });
        navigate("/admin/blog", { state: { showToast: id ? `${blog.title} edited successfully` : `${blog.title} created successfully` } });
      } catch (e) {
        setLoading(false);
        setDraftLoading(false);
        toast.error("SOmething went wrong!")
      }
    }
  };

  const handleDeleteImage = () => {
    setImagePreview(null);
    setBlog((prev) => ({
      ...prev,
      image: "",
    }));
  };
  const requiredErr = "This field is mandatory";
  const handleInputBlur = (e:any)=>{
    const  {name, value} = e.target;
    let errorMessage = '';
        switch (name) {
          case 'title':
            errorMessage = value.trim() === "" ?requiredErr : !(blogTitleRegex.test(value.trim())) ? "Enter Valid Name" : ""  ;
            break;
          case 'image':
            const image = e.target.files;
            errorMessage = !image[0] ? requiredErr : !['image/webp', 'image/jpg', 'image/jpeg', 'image/png'].includes(image[0].type) ? "Image should be in WEBP/JPG/JPEG/PNG Format" : ""
            break;
          }
          setErrors((prevErrors : any) => ({
            ...prevErrors,
            [name]: errorMessage
          }));
    }


    const validate = (formdata:FormData)=>{
      const image = formdata.get('image') as File;
      const tempErr = {
        title: formdata.get('title') === "" ?  requiredErr : !(blogTitleRegex.test(formdata.get('title')?.toString().trim() || "")) ? "Enter Valid Name" : ""  ,
       
        image: image && image.name === "" ? requiredErr : image && !['image/webp', 'image/jpg', 'image/jpeg', 'image/png'].includes(image.type) ? "Image should be in WEBP/JPG/JPEG/PNG Format" : ""
      }
      setErrors(tempErr)
        let hasErrors = Object.values(tempErr).some(error => error !== "")
        return hasErrors;
    }

    
    const handleCancel = ():void =>  {
        if(window.confirm('Your form data will be lost. Do you want to cancel?')){
          navigate('/admin/blog')
      }
    
    }

  return (
    <div className="px-40 ">
      <h1 className="font-bold text-2xl py-5 ">
        {!id ? "Create a Blog" : "Edit Blog"}
      </h1>
      <div className="bg-primary bg-opacity-[0.15]">
        <form className="p-4 flex flex-col gap-4" id="editorValue">
          <div className="flex flex-col relative mb-3">
            <InputField
              placeholder="Enter Blog Title"
              className="w-[100%]"
              fieldName="title"
              type="text"
              value={blog.title}  
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e)=>{handleInputBlur(e)}}
            />
            {errors && errors.title && <ErrorSpan error={errors.title} />}
          </div>
          <div className="flex flex-wrap items-center gap-2">
            {tags && tags.map((tag, index)=>{
              return (
                <div  id={index.toString()} className="flex items-center bg-primary b text-white  py-3 px-2">
                  <span className="me-1">{tag}</span><FontAwesomeIcon className="cursor-pointer" onClick={handleTagRemove} icon={faCircleXmark} />
                </div>
              )
            })}
              <input className="placeholder:text-grey outline-none py-3 px-3 h-10 md:h-12 w-full" type="text" placeholder="Add Tags" onKeyDown={handleKeyDown}></input>
          </div>
          
            {!blog.image  && <div className="flex gap-3">
            <div className="flex flex-col relative mb-3">
              <div>
              <label htmlFor="thumbnail">Blog Image: </label>
              <input
                type="file"
                id="thumbnail"
                name="image"
                onBlur={(e) => handleInputBlur(e)}
                onChange={handleImageChange}
                accept="image/*"
                ></input>
                </div>
              {errors && errors.image && <ErrorSpan error={errors.image} />}
                </div>
            </div>}
        
          {imagePreview && (
            <div className="flex items-center gap-3">
              <label htmlFor="thumbnail">Uploaded thumbnail: </label>
              <img src={imagePreview} alt="uploaded thumbnail" className="h-44 w-44"></img>
              <span
                className="underline hover:text-red-500"
                onClick={handleDeleteImage}
              >
                Delete Image
              </span>
            </div>
          )}


            <QuillEditor
              ref={quill}
              className=" mb-4 xl:pb-11 pb-16 bg-white h-[100vh]"
              theme="snow"
              value={blog?.description}
              onChange={(value) => {
                setBlog((prevBlog) => ({
                  ...prevBlog,
                  description: value,
                }));
                setValue(value);
              }}
              modules={modules}
              formats={formats}
              />

          <div className="flex gap-4 justify-end">
            <CustomButton
              onClick={() => handleSubmit("0")}
              title="Save as Draft"
              loading = {draftLoading}
            />
            <CustomButton onClick={() => handleSubmit("1")}  title="Publish" loading={loading}/>
            <CustomButton title="Cancel" onClick={handleCancel}/>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};
