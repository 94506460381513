
import { CustomButton } from './CustomButton'
import { writeFile, utils } from 'xlsx';
import { axiosInstance } from '../Interceptors/authInterceptor';

type ExportToExcelProps = {

    name:string,
   
}

export const ExportToExcel = ({ name }: ExportToExcelProps) => {

  var dataToExport:any = null;
  var header:any = null;

  const makeData = async ()=>{
    if(name === "Quotes"){
      const data = await axiosInstance.get(
        `/api/quotes?page=${1}&limit=100000000`,
        {
          withCredentials: true,
        }
      );
      
  
      const excelData = data.data.quotes?.map((item: any) => ({
        name: item.name,
        mailingAddress: item.mailingAddress,
        city: item.city,
        email: item.email,
        state: item.state,
        zip: item.zip,
        country: item.country,
        company: item.company,
        phone: item.phone,
        product: item.product.length > 0 ? item.product[0].name : "",
      }));
  
      dataToExport = excelData;
      const headers = [
        "name",
        "mailingAddress",
        "city",
        "email",
        "state",
        "zip",
        "country",
        "company",
        "phone",
        "product",
      ];
  
      header = headers;
  
    } else {
  
      const data = await axiosInstance.get(`/api/peptide-synthesis?page=${1}&limit=1000000`, {
        withCredentials: true
      })

  
      const excelData = data.data.syntheses?.map((peptide:any) => {
        const sequenceColumns:any = {};
        peptide.sequence.forEach((seq:any, index:number) => {
            sequenceColumns[`sequence_${index}_peptideSequence`] = seq.peptideSequence;
            sequenceColumns[`sequence_${index}_quantityMg`] = seq.quantityMg;
            sequenceColumns[`sequence_${index}_quantityGm`] = seq.quantityGm;
            sequenceColumns[`sequence_${index}_purity`] = seq.purity;
        });
    
        return {
            name: peptide.name,
            mailingAddress: peptide.mailingAddress,
            city: peptide.city,
            email: peptide.email,
            state: peptide.state,
            zip: peptide.zip,
            country: peptide.country,
            company: peptide.company,
            phone: peptide.phone,
            ...sequenceColumns, // Spread the dynamically created sequence columns
            message: peptide.message
        };
    });

    dataToExport = excelData;
    
      const synthesisHeader = [
        'name',
        'mailingAddress',
        'city',
        'email',
        'state',
        'zip',
        'country',
        'company',
        'phone',
        'sequence',
        'message',
      ]

      header = synthesisHeader;
    
    }
  }
  
  const handleExport = async ()=>{
        await makeData();
       var wb = utils.book_new();
       var ws = utils.json_to_sheet(dataToExport, { header: header });


       utils.book_append_sheet(wb, ws, "MySheet1");

       writeFile(wb, `${name}.xlsx`);

    }

  return (
    <div>
        <CustomButton title='Export' onClick={handleExport} />
    </div>
  )
}
