import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";




  export const CardSlider = ({children} : any) => {
    const NextArrow = (props: any)=>{
      const { onClick} = props;
      return (
        <div className={`slick-arrow`} onClick={onClick} style={{ right:'10px'}}>
          <FontAwesomeIcon icon={faArrowRight} className="text-primary border hover:bg-primary hover:text-white border-primary p-2  md:text-3xl sm:text-lg text-base absolute -end-5 md:-end-10 top-[50%] -translate-y-[50%]" />
        </div>
      )
    }
    
    function PrevArrow(props: any) {
      const { onClick} = props;
      return (
        <div className={`slick-arrow`} onClick={onClick} style={{ right:'10px'}}>
          <FontAwesomeIcon icon={faArrowLeft} className="text-primary border hover:bg-primary hover:text-white border-primary p-2 md:text-3xl sm-text-lg text-base absolute -start-5 md:-start-10 top-[50%] -translate-y-[50%] z-30" />
        </div>
      )
    }
    var settings = {
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 654,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="">

        <Slider {...settings} nextArrow={<NextArrow />}  prevArrow={<PrevArrow />} className="">
          {children}
        </Slider>
      </div>
    );
  };
