import {  useNavigate } from 'react-router-dom';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';

export const Unauthorized = () => {

  const navigate = useNavigate();
  useSetPageTitle("Unauthorized access");
  
  return (
    <div className='flex flex-col justify-center items-center my-10'>
        <span className='text-[10rem] font-bold'>4<span className='text-primary'>0</span>3</span>
        <p className='text-2xl'>Access Denied</p>
        <span onClick={()=>{navigate(-2)}} className='cursor-pointer text-xl underline mt-3'>Go Back</span>
    </div>
  );
};
