import React, { useEffect, useRef, useState } from 'react'
import { InputField } from '../../Components/InputField'
import { Product } from '../../Components/Product.types'
import { useAxios } from '../../Hooks/useAxios';

import { axiosInstance, useAxiosInterceptor } from '../../Interceptors/authInterceptor';
import { useNavigate, useParams } from 'react-router-dom';
import { partCatalogNoReg, partDescriptionReg, partNameReg, partPriceReg, partQuantityReg } from '../../regex';
import { ErrorSpan } from '../../Components/ErrorSpan';
import { useAuthContext } from '../../Hooks/useAuthContext';
import { CustomButton } from '../../Components/CustomButton';
import { ToastContainer, toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import  Switch  from 'react-switch';
import { LoadingSpinner } from '../../Components/LoadingSpinner';

export type Part = {
    id?: string,
    _id?: string,
    name: string,
    catalogNo: string,
    price: number | string,
    quantity: number | string,
    product: any[],
    description: string,
    createdAt?: Date,
    status: number,
    availability: number,
    order: number | string
}

export const CreatePart = () => {
    const {user} =  useAuthContext(); 
    const navigate= useNavigate();
    useEffect(() => {
        if (!user.user?.accessibility[1]) {
        navigate('/admin/unauthorized');
        }
    }, [user, navigate]);
    useAxiosInterceptor();
    const [loading, setLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<Product[]>();
    // eslint-disable-next-line
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [part, setPart] = useState<Part>({
        name: "",
        catalogNo: "",
        price: "",
        quantity: "",
        product: [],
        description: "",
        availability: 1,
        status: 1,
        order: ""
    });

    const [error, setError] = useState<any>({
        name: "",
        catalogNo: "",
        price: "",
        quantity: "",
        product: "",
        description: ""
    });

    const { id } = useParams();

    const formRef = useRef<HTMLFormElement>(null);
    const { response } = useAxios({
        method: 'get',
        url: '/api/product?category=1'
    })

    useEffect(() => {
        if (response != null) {
            setProducts(response.data.products);
        }
    }, [response]);

    const requiredErr = "This field is mandatory";

    const handleBlur = (event: any) => {
        const { name, value } = event.target;
        let errorMessage = '';

        switch (name) {
            case 'name':
                errorMessage = value === "" ? requiredErr : !(partNameReg.test(value.trim())) ? "Enter Valid Name" : "";
                break;
            case 'catalogNo':
                errorMessage = value === "" ? requiredErr : !(partCatalogNoReg.test(value.trim())) ? "Enter Valid Catalog Number" : "";
                break;
            case 'price':
                errorMessage = value === "" ? requiredErr : !(partPriceReg.test(value.trim())) ? "Price should be in decimal format" : "";
                break;
            case 'quantity':
                errorMessage = value === "" ? requiredErr : !(partQuantityReg.test(value.trim())) ? "Quantityt should be numeric" : "";
                break;
            case "order":
                errorMessage =  value === "" ? "" : Number(value) < 1 || Number(value) > 9999
                ? "Order should be between 1 and 9999"
                : ""
                break;
            case 'description':
                errorMessage = !(partDescriptionReg.test(value.trim())) ? "Description should be less than 200 characters" : "";
                break;
            case 'product':
                errorMessage = value === 0 ? requiredErr : "";
                break;
            default:
                break;
        }

        setError((prevErrors: any) => ({
            ...prevErrors,
            [name]: errorMessage
        }));
    };

    const validateData = () => {
        const tempErr = {
            name: part.name === "" ? requiredErr : !(partNameReg.test(part.name.trim())) ? "Enter Valid Name" : "",
            catalogNo: part.catalogNo === "" ? requiredErr : !(partCatalogNoReg.test(part.catalogNo.trim())) ? "Enter Valid Catalog Number" : "",
            price: part.price === "" ? requiredErr : !(partPriceReg.test(part.price.toString())) ? "Price should be in decimal format" : "",
            quantity: part.quantity === "" ? requiredErr : !(partQuantityReg.test(part.quantity.toString())) ? "Quantity should be numeric" : "",
            description: part.description && !(partDescriptionReg.test(part.description.trim())) ? "Description should be less than 200 characters" : "",
            product: part.product.length === 0 ? requiredErr : '',
            order: part.order === "" ? "" : Number(part.order) < 1 || Number(part.order) > 9999 ? "Order should be between 1 and 9999" : ""
        };
    
        setError(tempErr);
    
        return Object.values(tempErr).some(error => error !== "");
    }
    

    useEffect(() => {
        const getPart = async () => {
            try {
                const part = await axiosInstance.get(`/api/admin/part/${id?.toString()}`, {
                    withCredentials: true
                });

                setPart(part.data.result);
                setSelectedProducts(part.data.result.product.map((p:Product)=>p.id))
            
            } catch (error: any) {
                if(error && error.response.status === 500){
                    navigate('/admin/parts-and-consumables', {state: {showToast: "Something went wrong", code: 500}})
                }
            }
        };

        if (id) {
            getPart();
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        setPart((prev: Part) => ({
            ...prev,
            [name]: value,
        }));
    };
   

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const url = id ? `/api/part/${id}` : '/api/part';
        const method = id ? 'PUT' : 'POST';
        const hasError = validateData();
        try{
            const body:any = {...part, product: part.product.map((prod)=> prod._id || prod.id)}
            if (body.order === "") {
                delete body.order;
            }
            if (!hasError) {
                setLoading(true);
                const res = await axiosInstance({
                    url: url,
                    method: method,
                    data: body,
                    withCredentials: true,
                })
                if (res.status === 201 || res.status === 200) {
                    formRef.current?.reset();
                    navigate("/admin/parts-and-consumables", { state: { showToast: id ? `${part.name} edited successfully` : `${part.name} created successfully` } });
                }
            }
        } catch (e){
            setLoading(false);
            toast.error("Error submitting form");
        }

    }

    const handleDisableProduct = (isDisable:number) => {
        if(isDisable === 0){
            setPart((prev)=>{
                return {...prev, status: prev.status === 1 ? 0 : 1}
            })
        } else {
            setPart((prev)=>{
                return {...prev, availability: prev.availability === 1 ? 0 : 1}
            })
        }
    }

    const onSelect = (selectedList:any, selectedItem:any)=>{
        setPart((prev)=>{
            return {...prev, product:[...prev.product, selectedItem]}
        })
    }
    const onRemove = (selectedList:any, removedItem:any)=>{
        setPart((prev)=>{
            return {...prev, product: prev.product.filter(p=> p !== removedItem)}
        })
    }

    const handleCancel = ():void =>  {
        if(window.confirm('Your form data will be lost. Do you want to cancel?')){
          navigate('/admin/parts-and-consumables')
        }
    }
    

    return (
        <>
            {!response && <LoadingSpinner />}
            {response && <div className='px-40 '>
                <h1 className='font-bold text-2xl py-5 '>{!id ? "Add a New Part" : "Edit Part"}</h1>
                <form className='grid grid-cols-2 gap-7 bg-primary p-10 bg-opacity-[0.15] mb-10' onSubmit={handleSubmit} ref={formRef}>
                    <div className='flex flex-col relative'>
                        <InputField type="text" placeholder='Name *' fieldName='name' value={part?.name} onBlur={(e) => { handleBlur(e) }} onChange={handleChange} className={`${error.name ? " border-[1px] border-red-500" : ""}`} />
                        {error.name && <ErrorSpan error={error.name} />}
                    </div>
                    <div className='flex flex-col relative'>
                        <InputField type="text" placeholder='Catalog Number *' fieldName='catalogNo' onBlur={(e) => { handleBlur(e) }} value={part?.catalogNo} onChange={handleChange} className={`${error.catalogNo ? " border-[1px] border-red-500" : ""}`} />
                        {error.catalogNo && <ErrorSpan error={error.catalogNo} />}
                    </div>
                    <div className='flex flex-col relative'>
                        <InputField type="text" placeholder='Price *' fieldName='price' value={part?.price} onBlur={(e) => { handleBlur(e) }} onChange={handleChange} className={`${error.price ? " border-[1px] border-red-500" : ""}`} />
                        {error.price && <ErrorSpan error={error.price} />}
                    </div>
                    <div className='flex flex-col relative'>
                        <InputField type="number" placeholder='Quantity *' fieldName='quantity' value={part?.quantity} onBlur={(e) => { handleBlur(e) }} onChange={handleChange} className={`${error.quantity ? " border-[1px] border-red-500" : ""}`} />
                        {error.quantity && <ErrorSpan error={error.quantity} />}
                    </div>
                    <div className='flex flex-col relative'>
                        <Multiselect
                            className={`bg-white py-1 outline-none ${error.product ? " border-[1px] border-red-500" : ""}`}
                            options={products} 
                            selectedValues={part.product} 
                            onSelect={onSelect} 
                            onRemove={onRemove} 
                            displayValue="name"
                            placeholder='Select Product *' 
                        />
                         {error.product && <ErrorSpan error={error.product} />}
                    </div>
                    <div className="flex flex-col relative">
                        <InputField
                            type="number"
                            placeholder="Order"
                            fieldName="order"
                            onBlur={(e) => handleBlur(e)}
                            value={part.order}
                            onChange={handleChange}
                            className={`${
                            error.order ? " border-[1px] border-red-500" : ""
                            }`}
                        />
                        {error.order && <ErrorSpan error={error.order} />}
                    </div>
                    <div className='col-span-2 flex flex-col relative'>
                        <textarea placeholder='Description' rows={4} name='description' onBlur={(e) => { handleBlur(e) }} value={part?.description} className={`p-2 ${error.description ? " border-[1px] border-red-500" : ""}`} onChange={handleChange} />
                        {error.description && <ErrorSpan error={error.description} />}
                    </div>
                    <div className="flex gap-7">
              <div className="flex gap-6 justify-start ms-2 items-center">
                <label className="font-medium">Active</label>
                <Switch
                  name="disabled"
                  checked={part.status === 1}
                  onChange={()=>{handleDisableProduct(0)}}
                  onColor="#69A8AA"
                  onHandleColor="#056E72"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={40}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
              <div className="flex gap-6">
                <label className="font-medium">Available</label>
                <Switch
                  name="available"
                  checked={part.availability === 1}
                  onChange={()=>{handleDisableProduct(1)}}
                  onColor="#69A8AA"
                  onHandleColor="#056E72"
                  handleDiameter={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={40}
                  className="react-switch"
                  id="material-switch"
                />
              </div>
            </div>
                    <div className='col-span-2'>
                            <CustomButton title='Submit' type='submit'  loading={loading} />
                            <CustomButton title="Cancel" className='ms-2' onClick={handleCancel}/>
                    </div>
                    
                </form>
            </div>}
            <ToastContainer />
        </>

    )
}
