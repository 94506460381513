import PMLogo from "../assets/PMLogo.svg";
import { Link, useLocation } from "react-router-dom";
import ProductDropDown from "./ProductDropDown";
import { useEffect, useRef } from "react";
export const Navbar = () => {
  const location = useLocation();
  const navbarRef = useRef<any>(null);
  const navbar = document.getElementById("navbar-default");
  const handleNavbar = () => {
    if(navbar?.classList.contains("hidden")){
      navbar?.classList.remove("hidden");
    } else {
      navbar?.classList.add("hidden");

    }
  };
  useEffect(() => {
    const navbar = document.getElementById("navbar-default");
    navbar?.classList.add("hidden");
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        navbar?.classList.add("hidden");
      }
    };

    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  if (
    location.pathname.includes("login") ||
    location.pathname.includes("admin") || 
    location.pathname.includes("error")
  ) {
    return <></>;
  } else {
    return (

      <nav className="sticky top-0 bg-white z-40 shadow-md md:shadow-none" ref={navbarRef}>
        <div className="xl:px-32 lg:px-20 md:px-10 px-5 flex  items-center justify-between mx-auto p-2">
          <Link to="/">
            <img
              src={PMLogo}
              className="xl:w-full lg:w-11/12 w-[72%]"
              alt="Peptide Machines"
            />
          </Link>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg min-[920px]:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="navbar-default"
            aria-expanded="false"
            id="navbar-toggler"
            onClick={handleNavbar}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full max-[920px]:shadow-lg min-[920px]:block min-[920px]:w-auto absolute min-[920px]:relative top-12 min-[920px]:top-0 left-0 z-20"
            id="navbar-default"
          >
            <ul className="font-semibold flex flex-col xl:gap-1 gap-0 min-[920px]:gap-3 p-4 min-[920px]:p-0 mt-4 border border-gray-100 rounded-lg  min-[920px]:flex-row min-[920px]:space-x-8 rtl:space-x-reverse min-[920px]:mt-0 min-[920px]:border-0 bg-white list-none navbar text-[16px]">
              <li className="m-0 ">
                <Link
                  to="/"
                  onClick={handleNavbar}
                  className="block  xl:px-3 lg:px-1 px-1 py-2 rounded max-[920px]:hover:bg-gray-100  md:border-0 min-[920px]:p-0"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="m-0 ">
                <span className="block  xl:px-3 lg:px-1 px-1 py-2 rounded max-[920px]:hover:bg-gray-100  md:border-0  min-[920px]:p-0    ">
                  <ProductDropDown handleNavbar={handleNavbar} />
                </span>
              </li>
              <li className="m-0 ">
                <Link
                  to="/parts-and-consumables"
                  onClick={handleNavbar}
                  className="block  xl:px-3 lg:px-1 px-1  py-2 rounded max-[920px]:hover:bg-gray-100  md:border-0  min-[920px]:p-0"
                >
                  Parts & Consumables
                </Link>
              </li>
              <li className="m-0 ">
                <Link
                  to="/quote-a-peptide"
                  onClick={handleNavbar}
                  className="block  xl:px-3 lg:px-1 px-1 py-2  rounded max-[920px]:hover:bg-gray-100  md:border-0  min-[920px]:p-0    "
                >
                  Peptide Synthesis
                </Link>
              </li>
              <li className="m-0 ">
                <Link
                  to="/blogs"
                  onClick={handleNavbar}
                  className="block  xl:px-3 lg:px-1 px-1  py-2 rounded max-[920px]:hover:bg-gray-100  md:border-0  min-[920px]:p-0    "
                >
                  Blogs
                </Link>
              </li>
              <li className="m-0 ">
                <Link
                  to="/about-us"
                  onClick={handleNavbar}
                  className="block  xl:px-3 lg:px-1 px-1 py-2  rounded max-[920px]:hover:bg-gray-100  md:border-0  min-[920px]:p-0    "
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      // </nav>
    );
  }
};

// }
