import React, { createContext, useEffect, useReducer, ReactNode } from "react";

export type User = {
    status: number,
    username: string,
    accessibility: boolean[],
    role: string | number
}

type State = {
  user: User | null; 
};

type Action = {
  type: string;
  payload?: any;
};

const initialState: State = {
  user: JSON.parse(localStorage.getItem("user") || "null"), 
};

const authReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
        localStorage.removeItem("user");
        return {user:null};
    default:
      return state;
  }
};

export const AuthContext = createContext<{ state: State; dispatch: React.Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    dispatch({ type: "LOGIN", payload: state.user });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
