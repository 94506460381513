import { useState } from 'react';
import { nameReg, mailingAddressReg, cityReg, stateReg, countryReg, companyReg, phoneReg, emailReg, zipReg, msgReg} from '../regex'

const useRequestQuoteValidation = () => {
  
    const [errors, setErrors] = useState<any>({
        name: '',
        mailingAddress: '',
        email: '',
        state: '',
        zip: null,
        country: '',
        company: '',
        phone: null,
      })

  const requiredErr = "This field is required";

  

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>  ) => {
    const { name, value } = event.target;
    let errorMessage = '';

    switch (name) {
      case 'name':
        errorMessage = value === "" ? requiredErr : !(nameReg.test(value.trim())) ? "Enter Valid Name" : "";
        break;
      case 'mailingAddress':
        errorMessage = value === "" ? requiredErr : !(mailingAddressReg.test(value.trim())) ? "Enter Valid Mailing Address" : "";
        break;
      case 'city':
        errorMessage = value === "" ? requiredErr : !(cityReg.test(value.trim())) ? "Enter Valid City Name" : "";
        break;
      case 'email':
        errorMessage = value === "" ? requiredErr : !(emailReg.test(value.trim())) ? "Enter Valid Email" : "";
        break;
      case 'state':
        errorMessage = value === "" ? requiredErr : !(stateReg.test(value.trim())) ? "Enter Valid State Name" : "";
        break;
      case 'zip':
        errorMessage = value === "" ? requiredErr : !(zipReg.test(value.trim())) ? "Enter Valid ZipCode" : "";
        break;
      case 'country':
        errorMessage = value === "" ? requiredErr : !(countryReg.test(value.trim())) ? "Enter Valid Country Name" : "";
        break;
      case 'company':
        errorMessage = value === "" ? requiredErr : !(companyReg.test(value.trim())) ? "Enter Valid Company Name" : "";
        break;
      case 'phone':
        errorMessage = value === "" ? requiredErr : !(phoneReg.test(value.trim())) ? "Enter Valid number" : "";
        break;
      case 'category':
        errorMessage = value === "" ? requiredErr : "";
        break;
      case 'product':
        errorMessage = value === "" ? requiredErr : "";
        break;
      default:
        break;
    }

    setErrors((prevErrors:any) => ({
      ...prevErrors,
      [name]: errorMessage
    }));
  };

  const validateData = (formData: FormData) => {
    const tempErr = {
      phone: formData.get('phone') === "" ? requiredErr : !(phoneReg.test(formData.get('phone')?.toString().trim() || "")) ? "Enter Valid number" : "",
      name: formData.get('name') === "" ? requiredErr : !(nameReg.test(formData.get('name')?.toString().trim() || "")) ? "Enter Valid Name" : "",
      city: formData.get('city') === "" ? requiredErr : !(cityReg.test(formData.get('city')?.toString().trim() || "")) ? "Enter Valid City Name" : "",
      email: formData.get('email') === "" ? requiredErr : !(emailReg.test(formData.get('email')?.toString().trim() || "")) ? "Enter Valid Email" : "",
      state: formData.get('state') === "" ? requiredErr : !(stateReg.test(formData.get('state')?.toString().trim() || "")) ? "Enter Valid State Name" : "",
      zip: formData.get('zip') === "" ? requiredErr : !(zipReg.test(formData.get('zip')?.toString().trim() || "")) ? "Enter Valid ZipCode" : "",
      country: formData.get('country') === "" ? requiredErr : !(countryReg.test(formData.get('country')?.toString().trim() || "")) ? "Enter Valid Country Name" : "",
      company: formData.get('company') === "" ? requiredErr : !(companyReg.test(formData.get('company')?.toString().trim() || "")) ? "Enter Valid Company Name" : "",
      mailingAddress: formData.get('mailingAddress') === "" ? requiredErr : !(mailingAddressReg.test(formData.get('mailingAddress')?.toString().trim() || "")) ? "Enter Valid Mailing Address" : "",
      category: formData.get('category') === "" ? requiredErr : "",
      product: formData.get("product") === "" ? requiredErr : "",
      message: !(msgReg.test(formData.get('message')?.toString().trim() || "")) ? "Enter Valid Message" : ""
    }

    setErrors(tempErr);
    const errorCheck = Object.values(tempErr).some(error => error !== "");

    return errorCheck;
  }

  return { handleBlur, validateData, errors };
}

export default useRequestQuoteValidation;
