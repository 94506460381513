import React, {  useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CustomButton } from "../../Components/CustomButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProductType } from "../../Components/Products";
import {
  axiosInstance,
  useAxiosInterceptor,
} from "../../Interceptors/authInterceptor";
import { LoadingSpinner } from "../../Components/LoadingSpinner";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import {debounce} from 'lodash';
import { SearchBar } from "../../Components/SearchBar";


export const AdminHome = () => {
  useAxiosInterceptor();
  const [products, setProducts] = useState<ProductType[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line
  const [tableKey, setTableKey] = useState<any>();
  // eslint-disable-next-line
  const [queryString, setQueryString] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.showToast) {
      if (location.state.code) {
        toast.error(location.state.showToast);
      } else {
        toast.success(location.state.showToast);
      }
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user.user?.accessibility[0]) {
      navigate("/admin/unauthorized");
    }
  }, [user, navigate]);

  const fetchProducts = async (search: string, category:string) => {
    try {
      const response = await axiosInstance.get(
        `/api/admin/product?page=${currentPage}&limit=10&search=${search}${category ? `&category=${category}` : ''}`,
        {
          withCredentials: true,
        }
      );
      if (response != null && response.status === 200) {
        const { products, lastPage } = response.data;
        setProducts(products);
        setTotalPages(lastPage);
      }
    } catch (error: any) {
      if (error.status === 403) {
        navigate("/admin/blog");
      }
    }
  };

  const getCategory = (id: number) => {
    switch (id) {
      case 1:
        return "Peptide Synthesizers";
      case 2:
        return "Amino Acids";
      case 3:
        return "Reagents & Linkers";
      case 4:
        return "Resins";
    }
  };

  const handleCategoryChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCategory(e.target.value);
  };
  
 

  const handleSearchChange = debounce(async (search:string)=>{
    fetchProducts(search, category);
  }, 500)

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const cell = e.target as HTMLElement;

    const button = cell.parentElement?.parentElement;
    const id = button?.dataset.id;
    try {
      if (window.confirm("Are you sure to delete this product?")) {
        await axiosInstance.delete(`/api/product/${id}`, {
          withCredentials: true,
        });

        setProducts((prevProducts) =>
          prevProducts?.filter((product) => product.id !== id)
        );
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  useEffect(() => { 
    fetchProducts(queryString,category);
  }, [currentPage, category]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    return ()=>{
      handleSearchChange.cancel();
    }
  },[handleSearchChange])

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div key={tableKey} className="lg:px-40 mt-7">
        <div className="flex align-middle justify-between items-center mb-7">
          <div className="flex gap-6 justify-between items-center">
            <select
              className={`outline-none px-1 h-12 w-56 flex gap-3 font-medium`}
              onChange={handleCategoryChange}
              name="category"
            >
              <option value={''}>All</option>
              <option value={1}>Peptide Synthesizers</option>
              <option value={2}>Amino Acids</option>
              <option value={3}>Reagents & Linkers</option>
              <option value={4}>Resins</option>
            </select>
            <SearchBar onChange={handleSearchChange} />
          </div>
          <Link to="/admin/create-product" className="">
            <CustomButton title="Add Product" className="" />
          </Link>
        </div>
        {!products ? (
          <LoadingSpinner />
        ) : products && products.length !== 0 ? (
          <table className="table-fixed w-[100%] border-collapse mb-10">
            <thead className="">
              <tr className="text-[1rem] ">
                <th className="text-start py-3 w-[20%]">Product Name</th>
                <th className="text-start py-3">Catalog Number</th>
                <th className="text-start py-3 w-[20%]">CAS number</th>
                <th className="text-start py-3">Order</th>
                <th className="text-start py-3">Category</th>
                <th className="text-start py-3">Created</th>
                <th className="py-3 text-start">Active</th> 
                <th className="py-3 text-start">Available</th>
                <th className="text-start py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product: any, index: any) => {
                return (
                  <tr
                    className="text-start text-[0.97rem] font-medium  py-20 odd:bg-primary odd:bg-opacity-[0.15]"
                    key={index}
                  >
                    <td className="py-2 ps-1">{product.name}</td>
                    <td>{product.catalogNo}</td>
                    <td className="break-words">{product.casNo}</td>
                    <td>{product.order}</td>
                    <td>{getCategory(product.category)}</td>
                    <td>{new Date(product.createdAt).toLocaleDateString()}</td>
                    <td>{product.status === 1 ? "Yes" : "No"}</td>
                    <td>{product.availability === 1 ? "Yes" : "No"}</td>
                    <td className="flex gap-5 justify-start items-center py-2 text-primary text-2xl">
                      <Link to={`/admin/edit-product/${product.id}`}>
                        <button>
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                      </Link>
                      <button onClick={handleDelete} data-id={`${product.id}`}>
                        <FontAwesomeIcon className="" icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            <p className="font-bold text-3xl mx-auto py-28 text-center">
              No Products to show
            </p>
          </>
        )}
      </div>
      <div className="flex justify-center items-center gap-3 mt-5 text-xl mb-4">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div>
          Page{" "}
          <input
            className="w-10 outline-none text-center"
            type="number"
            value={currentPage}
            onChange={(e) => setCurrentPage(Number(e.target.value))}
          />{" "}
          <span className="me-2">Of</span> {totalPages}
        </div>

        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <ToastContainer />
    </>
  );
};
