
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Part } from './Product.types';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
type PartsPros = {
    parts?: Part[];
}
export const Parts = ({parts}: PartsPros) => {
  return (
    <div className='xl:px-32 lg:px-20 md:px-10 px-5 '>
        <h1 className='text-xl font-semibold'>Parts</h1>
        <table className='w-full text-start parts-table my-5'>
                <thead className='bg-primary text-white text-start'>
                    <tr >
                        <th className='text-start ps-1'>Part Name</th>
                        <th></th>
                        <th className='text-start'>Catalog Number</th>
                        <th className='text-start'>Prices</th>
                        <th className='text-start'>Qty</th>
                    </tr>
                </thead>
                <tbody className='font-medium'>
                    {parts && parts.map((part, index)=>{
                    return (
                    <tr key={index} className='odd:bg-primary odd:bg-opacity-[0.15]'>
                        <td>
                            <div className='ps-1'>

                            {part.name}
                            </div>
                        </td>
                        <td> 
                            {!part.availability && <FontAwesomeIcon className='text-red-600' title='Not Available' icon={faCircleMinus} />}
                        </td>
                        <td>
                            {part.catalogNo}
                        </td>
                        <td>
                            $ {part.price}
                        </td>
                        <td>
                            {part.quantity}
                        </td>
                    </tr>
                    )
                })}
                </tbody>
                </table>
       
    </div>
  )
}
