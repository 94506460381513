import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CustomButton } from '../../Components/CustomButton'
import { Part } from './CreatePart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { axiosInstance, useAxiosInterceptor } from '../../Interceptors/authInterceptor';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { useAuthContext } from '../../Hooks/useAuthContext';
import { ToastContainer, toast } from 'react-toastify';

export const AdminPartsAndConsumables = () => {
  useAxiosInterceptor();
  const {user } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user.user?.accessibility[1]) {
      navigate('/admin/unauthorized');
    }
  }, [user, navigate]);
  const [parts, setParts] = useState<Part[]>();

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.showToast) {
      toast.success(location.state.showToast);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location,navigate]);

  const fetchParts = async () => {
    try{
      const response = await axiosInstance.get(`/api/admin/part`, {
        withCredentials: true,
      });
      if (response != null) {
        setParts(response.data.result.parts);
      }
    } catch(error:any){
      if(error.status === 403){
        navigate('/admin/blog')
      }
    }
  };
  useEffect(() => {
    fetchParts();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
      const cell = e.target as HTMLElement;

      const button = cell.parentElement?.parentElement;
      const id = button?.dataset.id;

      try {
        if (window.confirm("Are you sure to delete this part?") && id) {
          await axiosInstance.delete(`/api/part/${id}`, {
            withCredentials: true,
          });
          toast.success("Part deleted Successfully");
          fetchParts();
        }
      } catch (error) {
        toast.error("Error Deleting Part")
      }
    };

  return (
    <div className='px-40 mt-7'>
        <div className="flex justify-end">
          <Link to="/admin/create-part">
            <CustomButton title="Add Part" className=" mb-7" />
          </Link>
        </div>
        {!parts && <LoadingSpinner />}
        {parts && <table className='table-fixed w-[100%]'>
        <thead className="">
              <tr className="text-[1.2rem] ">
                <th className="text-start py-3">Part Name</th>
                <th className="text-start py-3">Catalog Number</th>
                <th className='text-start py-3'>Order</th>
                <th className="text-start py-3">Quantity</th>
                <th className="text-start py-3">Price</th>
                <th className='text-start py-3'>Created</th>
                <th className='text-start py-3'>Active</th>
                <th className='text-start py-3'>Available</th>
                <th className="text-start py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
                {parts && parts.length === 0 && (
                  <tr>
                    <td colSpan={5} className='text-center text-lg font-medium p-4'>No Parts to display</td>
                  </tr>
                )}
                {parts && parts.length > 0 && parts.map((part)=>{
                  return (
                    <tr key={part.id} className='text-start text-[1.1rem] font-medium  py-20 odd:bg-primary odd:bg-opacity-[0.15]'>
                        <td className='ps-1'>{part.name}</td>
                        <td>{part.catalogNo}</td>
                        <td>{part?.order}</td>
                        <td>{part.quantity}</td>
                        <td>{part.price}</td>
                        <td>{part.createdAt && new Date(part.createdAt).toLocaleDateString()}</td>
                        <td>{part.status === 1 ? "Yes" : "No"}</td>
                        <td>{part.availability === 1 ? "Yes" : "No"}</td>
                        <td className="flex gap-5 justify-start items-center py-2 text-primary text-2xl">
                        <Link to={`/admin/edit-part/${part.id}`}>
                          <button>
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </Link>
                        <button
                          onClick={handleDelete}
                          data-id={`${part.id}`}
                        >
                          <FontAwesomeIcon className="" icon={faTrash} />
                        </button>
                      </td>
                    </tr>

                  )
                })}

            </tbody>
        </table>}

        <ToastContainer />
    </div>
  )
}
