import { useState, useEffect } from "react";
import { ProductCard } from "../Components/ProductCard";
import Breadcrumb from "../Components/Breadcrumb";
import { ProductType } from "../Components/Products";
import { LoadingSpinner } from "../Components/LoadingSpinner";
import { useSetPageTitle } from "../Hooks/useSetPageTitle";
import axios from "axios";
import {debounce} from 'lodash';
import { SearchBar } from "../Components/SearchBar";

export const ProductList =  () => {
  useSetPageTitle("Peptide Synthesizers");
  const [products, setProducts] = useState<ProductType[]>();


  const fetchProducts =async (queryString: string = '')=>{
    const response = await axios.get(`/api/product?category=1&page=1&limit=100&search=${queryString}`);

    if (response != null) {
      setProducts(response.data.products);
    }
  }

  const handleSearchChange = debounce(async (search:string)=>{
    fetchProducts(search);
  }, 500)

  useEffect(() => {
    fetchProducts()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    return ()=>{
      handleSearchChange.cancel();
    }
  },[handleSearchChange])
    return (
      <div className="xl:px-32 lg:px-20 md:px-10 px-5 mb-5">
      <div className="flex sm:justify-between sm:items-center mb-5 sm:mb-0 sm:flex-row flex-col">

        <div className='md:my-5 my-3'>
            <h2 className='font-bold text-xl md:text-2xl'>Peptide Synthesizers</h2>  
            <Breadcrumb />
        </div>
        <SearchBar onChange={handleSearchChange} />
      </div>
        {products?.length === 0 && <div className='text-2xl font-bold flex justify-center items-center h-[63vh]'>
                <p className='-mt-32'>
                    No products found
                </p>
            </div>}
        {products ? <div className="grid sm:grid-cols-2   md:grid-cols-3 gap-5">
          {products &&
            products.map((product, index) => {
              return <ProductCard key={index} {...product} />;
            })}
        </div> :  <LoadingSpinner />}
      </div>
    );
  
};
