import React from "react";
import { CustomButton } from "./CustomButton";
import About from "../assets/aboutUs.webp";
import { Link } from "react-router-dom";

export const AboutUs = () => {
  return (
    <div className="relative">
      <div
        className="absolute inset-0 bg-cover md:hidden  -z-10"
        style={{ backgroundImage: `url(${About})`, opacity: "0.4" }}
      ></div>
      <div className="grid md:grid-cols-2 grid-cols-1  place-items-center md:my-16 py-10 xl:px-32 lg:px-20 md:px-10 px-5">
        <div className="flex flex-col gap-3 md:gap-7 justify-between items-start">
          <h3 className="font-bold text-xl md:text-2xl lg:text-3xl">
          Welcome Peptide Machines - Your Trusted Healthcare Solution
          </h3>
          <p className="font-medium text-base  md:text-[1.05rem] xl:text-lg">
            Company was stablished by engineers and peptide chemists who have
            been in peptide and organic synthesis and instrumentation since
            1980's.
          </p>
          <p className=" font-medium text-base md:text-[1.05rem] xl:text-lg xl:mb-6">
            We are now providing all your peptide synthesis needs from amino
            acids, resins and reagents to top quality custom peptide synthesis
            and soon we will be adding new synthesizers to our product list.
            Keep checking back for the latest details!
          </p>
          <Link to="/about-us">
            <CustomButton title="About Us" />
          </Link>
        </div>

        <img
          src={About}
          alt="About us image"
          className="order-first md:order-last mt-6 md:mt-0 md:ms-5 hidden md:block"
        />
      </div>
    </div>
  );
};
