import { useEffect } from "react";

type ModalProps = {
  children: React.ReactNode;
  setShowModal: React.Dispatch<React.SetStateAction<any>>;
};

export const Modal = ({ children, setShowModal }: ModalProps) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="pointer-events-auto">
      <div className="overflow-hidden">
        <div
          className="fixed inset-0 w-full h-full z-40 bg-black opacity-40"
          onClick={handleCloseModal}
        ></div>
        <div
          id="default-modal"
          className="fixed top-[50%] z-50 left-[50%] w-[60%]   -translate-y-[50%] -translate-x-[50%] "
        >
          <div className="relative py-4">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex items-center justify-between p-4 md:p-5 border-b bg-primary rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-white">
                  Product Details
                </h3>
                <button
                  onClick={handleCloseModal}
                  type="button"
                  className="text-white bg-transparent hover:bg-white hover:text-primary rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                  data-modal-hide="default-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className=" overflow-y-auto h-[30rem]">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
