import { faFile, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  useEffect, useState } from "react";
import { Modal } from "../../Components/Modal";

import { axiosInstance, useAxiosInterceptor } from "../../Interceptors/authInterceptor";
import { ExportToExcel } from "../../Components/ExportToExcel";
import { LoadingSpinner } from "../../Components/LoadingSpinner";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

type Sequence = {
    peptideSequence: string,
    quantityMg: number,
    quantityGm: number,
    purity: number
}

type Peptide = {
    id: string,
    name: string,
    mailingAddress: string,
    city: string,
    email: string,
    state: string,
    zip:string,
    country: string,
    company: string,
    phone: string,
    sequence: Sequence[],
    document: string,
    message: string | null
}



export const AdminPeptideForm = () => {
    useAxiosInterceptor();
    const [peptides, setPeptides] = useState<Peptide[]>();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showModal, setShowModal] = useState(false);
    const [peptideDetail, setPeptideDetail] = useState<Peptide |  null>();
    const {user } = useAuthContext();
    const navigate = useNavigate();
    useEffect(() => {
      if (!user.user?.accessibility[3]) {
        navigate('/admin/unauthorized');
      }
    }, [user, navigate]);

    const fetchPeptideQuotes = async (page: number) => {
        try {
          const response = await axiosInstance.get(`/api/peptide-synthesis?page=${page}&limit=10`,{
            withCredentials:true
          });
          const { syntheses, lastPage } = response.data;
          setPeptides(syntheses);
          setTotalPages(lastPage);
        } catch (error:any) {
          if(error.status === 403){
            navigate('/admin/blog')
          }
        }
      };
    
      useEffect(()=>{
        if(!showModal){
          setPeptideDetail(null);
        }
      },[showModal])
    
      useEffect(() => {
        fetchPeptideQuotes(currentPage);
      }, [currentPage]);

      const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
      };
    
      const handleViewQuote = async (id: string) => {
        setShowModal(true);
        const {data} = await axiosInstance.get( `/api/peptide-synthesis/${id}` ,{
          withCredentials:true
        });
        setPeptideDetail(data.synthesis);
      };

      

    return (
      <>
        <div className="lg:px-40 mt-7">
          <div className="flex justify-end">
            <ExportToExcel  name="PeptideSyntheses" />
          </div>
          {!peptides && <LoadingSpinner />}
      {peptides && <table className="table-fixed w-[100%] border-collapse mb-10">
        <thead className="">
          <tr className="text-[1.2rem] ">
            <th className="py-3">Name</th>
            <th className="py-3">Company</th>
            {/* <th className='py-3'>Products</th> */}
            <th className="py-3">Phone</th>
            <th className="py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {peptides &&
            peptides.map((peptide: any, index: any) => {
              return (
                <tr
                key={index}
                className="text-center text-[1.1rem] font-medium  py-20 odd:bg-primary odd:bg-opacity-[0.15]"
                >
                  <td className="py-2 ps-1">{peptide.name}</td>
                  <td>{peptide.company}</td>
                  <td>{peptide.phone}</td>
                  <td className="flex gap-5 justify-center items-center py-2 text-primary text-2xl">
                    <button
                      onClick={() => {
                        handleViewQuote(peptide._id);
                      }}
                      >
                      <FontAwesomeIcon icon={faFile} />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>}
      <div className="flex justify-center items-center gap-3 mt-5 text-xl mb-4">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div>
          Page{" "}
          <input
            className="w-10 outline-none text-center"
            type="number"
            value={currentPage}
            onChange={(e) => setCurrentPage(Number(e.target.value))}
            />{" "}
          <span className="me-2">Of</span> {totalPages}
        </div>

        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {showModal && 
            <Modal setShowModal = {setShowModal}>
                <div className="flex flex-col ">
                    <div className={`grid grid-cols-2 py-2 font-medium bg-primary odd:bg-opacity-[0.15]`}>
                        <strong className="px-10">Name:</strong>
                        <span>{peptideDetail?.name}</span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium `}>
                        <strong className="px-10">Mailing Address:</strong>
                        <span>{peptideDetail?.mailingAddress} </span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium bg-primary odd:bg-opacity-[0.15]`}>
                        <strong className="px-10">City:</strong>
                        <span>{peptideDetail?.city}</span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium`}>
                        <strong className="px-10">Email:</strong>
                        <span>{peptideDetail?.email}</span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium bg-primary odd:bg-opacity-[0.15]`}>
                        <strong className="px-10">State:</strong>
                        <span>{peptideDetail?.state}</span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium`}>
                        <strong className="px-10">Zip:</strong>
                        <span>{peptideDetail?.zip}</span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium bg-primary odd:bg-opacity-[0.15]`}>
                        <strong className="px-10">Company:</strong>
                        <span>{peptideDetail?.company}</span>
                    </div>
                    <div className={`grid grid-cols-2 py-2  font-medium`}>
                        <strong className="px-10">Phone:</strong>
                        <span>{peptideDetail?.phone}</span>
                    </div>
                    {peptideDetail?.message && <div className={`grid grid-cols-2 py-2 bg-primary bg-opacity-[0.15] font-medium`}>
                        <strong className="px-10">Message:</strong>
                        <span>{peptideDetail?.message}</span>
                    </div>}
                    <div>
                    <strong className="px-10 text-lg  grid grid-cols-1" >Peptide Sequences:</strong>
                    {peptideDetail?.sequence.map((seq, index)=>{
                        return(
                          <div key={index} className={` py-2 px-10 font-medium ${index % 2 === 0 ? 'bg-primary bg-opacity-[0.15] ': ''}`}>
                                <div className="grid grid-cols-2">
                                    <span className="font-semibold">Sequence Name:</span>
                                    <span className="">{seq.peptideSequence}</span>
                                </div>
                                <div>
                                    <div className="grid grid-cols-2">
                                        <span className="font-semibold">Quantity in MG:</span>
                                        <span>{seq.quantityMg}</span>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <span className="font-semibold">Quantity in GM:</span>
                                        <span>{seq.quantityGm}</span>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <span className="font-semibold">Purity:</span>
                                        <span>{seq.purity}</span>
                                    </div>
                                </div>
                            </div>
                        )
                      })}
                    </div>

                    {peptideDetail?.document && <div className={`grid grid-cols-2 py-2  font-medium `}>
                        <strong className="px-10">Document:</strong>
                        <span className="underline"><a href={peptideDetail?.document} download={true}>Download</a></span>
                    </div>}
                </div>    
            </Modal>
      }
      </div>
      </>
    )
}
