import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
const userNamesById:any = { '64157d7b94aee2bfe2e63057': "Discovery 12" };

const DynamicUserBreadcrumb = ({ match } : any) => (
    <span>{userNamesById[match.params.id]}</span>
);

const routes = [
  { path: '/products/:id', breadcrumb: DynamicUserBreadcrumb },
  { path: '/products', breadcrumb: 'Products' }
];

function Breadcrumb() {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <nav className='font-semibold my-2'>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <div className='inline' key={index}>
        <Link className={'text-sm md:text-base'}  to={match}>
          {breadcrumb} 
        </Link>
        {index !== breadcrumbs.length - 1 && <span className='mx-1 text-sm md:text-base'>&gt;</span>}
        </div>
      ))}
    </nav>
  );
}

export default Breadcrumb;