import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { Testimonial } from "./Testimonials";

interface TestimonyCardProps  {
  testimony: Testimonial
}

export const TestimonyCard = ({testimony}: TestimonyCardProps) => {
  return (
    <div className="flex flex-col  mx-5">
        <div className="flex text-xl mb-4">
            <FontAwesomeIcon icon={faQuoteLeft} className="text-primary me-1 mt-1"/>
            <h4 className="font-bold">{testimony.product}</h4>
        </div>
        <p className="text-medium mb-4 min-h-fit font-medium text-justify">
            {testimony.testimony}
        </p>
        <p className="font-bold mb-1">{testimony.writer}</p>
        <p className="font-medium">{testimony.organization}</p>
    </div>
  )
}
