import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faPinterestP, faTwitter } from "@fortawesome/free-brands-svg-icons";
import {  faPhoneVolume } from "@fortawesome/free-solid-svg-icons";    
import PMLogo from '../assets/PMLogoWhite.svg'
import { Link, useLocation } from "react-router-dom";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
export const Footer = () => {
    const location = useLocation();

    if(location.pathname.includes('admin') || location.pathname.includes('login') || location.pathname.includes('error')){
        return <></>
    }
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 bg-primary text-white justify-center xl:px-32 lg:px-20 md:px-10 px-5 py-10">
        <div className="flex flex-col gap-4 col-span-2 sm:col-span-3 md:col-span-1">
            <img src={PMLogo} alt="" className="w-44 md:w-56"/>
            <p className="leading-6 md:leading-8">
            The world's premier manufacturer of efficient, innovative, patented, automated multiple peptide synthesizers.
            </p>
            <h3 className='font-bold text-xl '>Social Support</h3>
            <div className="flex gap-2 text-xl md:text-2xl">
                <FontAwesomeIcon icon={faFacebook} className="border-2 border-white p-1 md:p-2 hover:text-primary hover:bg-white"/>
                <FontAwesomeIcon icon={faInstagram} className="border-2 border-white p-1 md:p-2 hover:text-primary hover:bg-white" />
                <FontAwesomeIcon icon={faTwitter} className="border-2 border-white p-1 md:p-2 hover:text-primary hover:bg-white"/>
                <FontAwesomeIcon icon={faPinterestP} className="border-2 border-white p-1 md:p-2 hover:text-primary hover:bg-white"/>
            </div>
        </div>
        <div className="md:ms-14 mt-10 md:mt-0">
            <h3 className='font-bold text-xl mb-3'>All Prodcts</h3>
            <ul className="flex flex-col text-sm sm:text-base gap-1 md:gap-3 font-medium  list-none">
                <li>
                    <Link to="/peptide-synthesizers">Peptide Synthesizers</Link>
                </li>
                <li>
                    <Link to="/amino-acids">Amino Acids</Link>
                </li>
                <li>
                    <Link to="/reagents-and-linkers">Reagent and Linkers</Link>
                </li>
                <li>
                    <Link to="/resins">Resins</Link>
                </li>
            </ul>
        </div>
        <div className="md:ms-14 mt-10 md:mt-0">
            <h3 className='font-bold text-xl mb-3'>All Pages</h3>
            <ul className="flex flex-col text-sm sm:text-base gap-1 md:gap-3 font-medium  list-none">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/parts-and-consumables">Parts & Consumables</Link>
                </li>
                <li>
                    <Link to="/quote-a-peptide">Peptide Synthesis</Link>
                </li>
                <li>
                    <Link to="/request-quote">Request A Quote</Link>
                </li>
                <li>
                    <Link to="/blogs">Blog</Link>
                </li>
                <li>
                    <Link to="/about-us">About Us</Link>
                </li>
            </ul>
        </div>
        <div className="mt-10 md:mt-0 col-span-2 sm:col-span-1">
            <h3 className='font-bold text-xl mb-3'>Contact Info</h3>
            <ul className="flex flex-col text-sm sm:text-base gap-3 font-medium list-none">
                <li>
                    <FontAwesomeIcon icon={faPhoneVolume} className="me-1 md:me-2"/>
                    <span>502-767-5870</span>
                </li>
                <li>
                    <FontAwesomeIcon icon={faEnvelope} className="md:me-2 me-1"/>
                    <a href="mailto:sales@peptidemachines.com" className="text-sm sm:text-base">sales@peptidemachines.com</a>
                </li>
                
            </ul>
            
            
        </div>
    </div>
  )
}
