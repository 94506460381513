import { useState, useEffect, useRef } from 'react'
import Request from '../assets/requestQuote.webp'
import { CustomButton } from '../Components/CustomButton'
import { InputField } from '../Components/InputField';
import { Product } from '../Components/Product.types';
import { useAxios } from '../Hooks/useAxios';
import axios from 'axios';

import { ErrorSpan } from '../Components/ErrorSpan';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSetPageTitle } from '../Hooks/useSetPageTitle';
import useRequestQuoteValidation from '../Hooks/useRequestQuoteValidation';

type Quote = {
  name: string,
  mailingAddress: string,
  email: string,
  state: string,
  zip: number | null,
  country: string,
  companyName: string,
  phone: number | null,
  category: number | null,
  product: string,
  recaptcha: string | null
}

export const RequestAQuote = () => {
  useSetPageTitle("Request A Quote");
  const sitekey:any = process.env.REACT_APP_CAPTCHA_KEY
  const [products, setProducts] = useState<Product[]>();
  const [categories, setCategories] = useState<any>();
  const [captchaErr, setCaptchErr] = useState<string>();
  const recaptchaRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>();
  const { handleBlur, validateData, errors } = useRequestQuoteValidation();

  const formRef = useRef<HTMLFormElement>(null);
  const {response} = useAxios({
      method: 'get',
      url: '/api/category'
  })
  useEffect(()=>{
    if(response != null){
      setCategories(response.data.category);
    }
  },[response])

  const [quoteDetails, setQuoteDetails] = useState<Quote>({
    name: '',
    mailingAddress: '',
    email: '',
    state: '',
    zip: null,
    country: '',
    companyName: '',
    phone: null,
    category: null,
    product: '',
    recaptcha: null,
  }
  )


  const  handleChange = async (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if(name === 'category'){
      if(event.target.value){
        const data = await axios.get(`/api/product?category=${event.target.value}`)
        setProducts(data.data.products);
      } else {
        setProducts([]);
      }
    }
    setQuoteDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  
  const handleRecaptcha = (value:any)=>{
    if(!value) setCaptchErr("Captcha is required");
    setQuoteDetails((prev)=>({
      ...prev,
      recaptcha: value
    }))
    setCaptchErr("");
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>)=>{
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    validateData(formData);
    if(!quoteDetails.recaptcha){
      setCaptchErr("Captcha is required");
      return ;
    }
    try{
      if(!Object.values(errors).some(error => error !== "")){
        setLoading(true)
        await axios({
          method: 'post',
          url: '/api/quotes',
          data: quoteDetails
        });
        formRef.current?.reset();
        recaptchaRef.current?.reset();
        setLoading(false)
        toast.success("Form submitted");
      }
  
        
  } catch(e: any){
      setLoading(false);
      toast.error("Error submitting the form");
  }
  }

  return (
    <>
    <div>
        <div style={{backgroundImage: `url(${Request})`}} className='xl:px-32 lg:px-20 md:px-10 px-5 py-14'>
             <h1 className='text-xl md:text-3xl font-bold text-white'>Request A Quote</h1>
             <p className='text-base md:text-lg font-medium text-white'>If you got any questions, please do not hesitate to send us a message. We reply within 24 hours !</p>
        </div>
        <div className='xl:px-32 lg:px-20 md:px-10 px-5'>

        <form className=' grid md:grid-cols-2 grid-cols-1  items-start gap-5 bg-primary bg-opacity-[0.15] p-5 md:p-10 my-10' onSubmit={handleSubmit} id='quote-form' ref={formRef}>
        <div className='flex flex-col relative'>
              <InputField type="text" className={`${errors.name ? " border-[1px] border-red-500" : ""}`} placeholder='Name *' fieldName='name' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.name && <ErrorSpan error={errors.name} />}
        </div>
              
        <div className='flex flex-col relative'>
              <InputField type="text" className={`${errors.mailingAddress ? " border-[1px] border-red-500" : ""}`} placeholder='Mailing Address *' fieldName='mailingAddress' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.mailingAddress && <ErrorSpan error={errors.mailingAddress} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text" className={`${errors.city ? " border-[1px] border-red-500" : ""}`} placeholder='City *' fieldName='city' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.city && <ErrorSpan error={errors.city} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text"className={`${errors.email ? " border-[1px] border-red-500" : ""}`} placeholder='Email *' fieldName='email' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.email && <ErrorSpan error={errors.email} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text"className={`${errors.state ? " border-[1px] border-red-500" : ""}`} placeholder='State *' fieldName='state' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.state && <ErrorSpan error={errors.state} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text"className={`${errors.zip ? " border-[1px] border-red-500" : ""}`} placeholder='Zip *' fieldName='zip' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.zip && <ErrorSpan error={errors.zip} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text"className={`${errors.country ? " border-[1px] border-red-500" : ""}`} placeholder='Country *' fieldName='country' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.country && < ErrorSpan error={errors.country} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text"className={`${errors.company ? " border-[1px] border-red-500" : ""}`} placeholder='Company Name *' fieldName='company' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.company && < ErrorSpan error={errors.company} />}
        </div>
        <div className='flex flex-col relative'>
              <InputField type="text"className={`${errors.phone ? " border-[1px] border-red-500" : ""}`} placeholder='Phone *' fieldName='phone' onBlur={(e)=>handleBlur(e)} onChange={handleChange}/>
              {errors.phone && < ErrorSpan error={errors.phone} />}
        </div>
        <div className='flex flex-col relative'>
              <select className={`outline-none px-1 h-10 md:h-12 ${errors.category ? "border-[1px] border-red-500" : ""}`} name='category' onBlur={(e)=>handleBlur(e)} onChange={handleChange}>
                <option value="" >Category *</option>
                {categories && categories.map((category: any)=>{
                  return (
                    <option key={category.id} value={category.id}>{category.name}</option>
                    )
                  })}
              </select>
              {errors.category && <ErrorSpan error={errors.category} />}
        </div>
        <div className='flex flex-col'>
          <div className="flex flex-col relative">

              <select className={`outline-none px-1 h-10 md:h-12 ${errors.product ? "border-[1px] border-red-500" : ""}`}  name='product' onBlur={(e)=>handleBlur(e)} onChange={handleChange}>
                <option value="" >Select Product *</option>
                {products && products.map((product,index)=>{
                  return (
                    <option key={product.id} value={product.id}>{product.name}</option>
                    )
                  })}
               
              </select>
              {errors.product && <ErrorSpan error={errors.product} />}
          </div>
          <div className="flex flex-column relative">
              <ReCAPTCHA sitekey={sitekey} className='mt-6 captcha' onChange={handleRecaptcha} ref={recaptchaRef}/>
              {captchaErr && <ErrorSpan error={captchaErr} />}
          </div>
        </div>
        <div className='flex flex-col relative'>
              <textarea className='p-2' name="message" placeholder="Message" rows={4} />
              {errors.message && <ErrorSpan error={errors.message}/>}
        </div>
              <CustomButton loading={loading} type="submit" title='Submit' />
        </form>
        </div>
    </div>
    <ToastContainer theme="colored"/>
</>
  )
}
