import { CardSlider } from "./CardSlider";
import { TestimonyCard } from "./TestimonyCard";

export interface Testimonial {
  product: string;
  testimony: string;
  writer: string;
  organization?: string;
}

export const Testimonials = () => {
  const testimonials: Testimonial[] = [
    {
      product: "Discovery-4",
      testimony:
        "The synthesizer has been able to successfully make wide range of peptides with good yield and purity. The machine itself is very sturdy and robust. The vessels that hold the resin are easily washed and reused. Software interface is great, easy to use. It is really a synthesizer for peptide chemists!",
      writer: "David Write",
      organization: "Professor, Department of Chemistry, Vanderbilt Universit",
    },
    {
      product: "Discovery-4",
      testimony:
        "In November 2023, we bought a Discovery-4 peptide synthesizer. The set-up of the Discovery-4 was very easy and the technical support was excellent. It has been very successful in our laboratory producing quality peptides of varying length from 5 to around 60 amino acids in good yields. The synthesizer has proved to be extremely reliable and technical support was superb.",
      writer: "Biopeptide Co., Inc.",
    },
  ];
  return (
    <div className="xl:px-32 lg:px-20 md:px-10 px-5 bg-primary w-[100%]  bg-opacity-[0.3] py-10">
      <h1 className="font-bold text-3xl mb-10">Testimonials</h1>
      <div className="grid grid-cols-2">
        {testimonials &&
          testimonials.length <= 3 &&
          testimonials.map((testimonial, index) => {
            return <TestimonyCard testimony={testimonial} key={index} />;
          })}
      </div>
      {testimonials && testimonials.length > 3 && (
        <CardSlider>
          {testimonials.map((testimonial, index) => {
            return <TestimonyCard testimony={testimonial} key={index} />;
          })}
        </CardSlider>
      )}
    </div>
  );
};
