import { Link } from 'react-router-dom'
import { ProductType } from './Products'
import { CustomButton } from './CustomButton'

export const Product = ({ product, odd }: { product: ProductType } & { odd: boolean }) => {

  return (
    <div>   
      <div className={`grid md:grid-cols-12 grid-cols-1 justify-center items-center xl:px-32 lg:px-20 md:px-10 px-5 md:py-16 sm:py-10 py-7 bg-opacity-20 ${odd ? 'bg-primary' : 'bg-white'} sm:text-left text-center`} >
        <img src={`${product.image}`} alt="" className='md:col-span-3 sm:col-span-1  mb-10 md:mb-0 md:mx-0 mx-auto' />
        <div className='md:col-span-1'></div>
        <div className='px-5 sm:px-20 md:px-0 sm:col-span-10  md:col-span-8'>
          <h1 className='text-lg md:text-2xl font-bold mb-3'>{product.name}</h1>
          <p className='text-base mb-3'>{product.catalogNo}</p>
          <ul className='hidden list-[square] text-sm sm:text-base ms-5 mb-4 sm:flex flex-col gap-3'>
            {product.keyPoints.map((key_point, index) => {
              if (index < 8) {
                return (
                  <li key={index} className='leading-5 sm:leading-6'>{key_point}</li>
                )
              }
              return null;
            })}
          </ul>
          <Link to={`/peptide-synthesizers/${product.slug}`} >
            <CustomButton title='More info' />
          </Link>
        </div>
      </div>
    </div>
  )
}
