import { CustomButton } from './CustomButton'
import { Blog } from '../Pages/Admin/AdminBlog'
import { Link } from 'react-router-dom'

type BlogCardProp = {
  blog: Blog
}

export const BlogCard = ({blog} : BlogCardProp) => {
  const regex = /(<([^>]+)>)/gi;
    const newString = blog.description.replace(regex, " ");
  return (
    <div className='border mb-4 m-2 shadow-md hover:outline hover:outline-primary  border-[#C4C4C4]'>
        <div>
            <img src={blog.thumbnail.toString()}  alt="" className='w-full h-60 object-cover'/>
        </div>
        <div className='flex flex-col gap-4 items-start p-4'>
            <h4 className='font-bold text-lg'>{blog.title}</h4>
            <p className='h-[9rem] overflow-hidden'>{newString.substring(0,200)}...</p>
            <Link to={`/blog/${blog.slug}`}>
              <CustomButton title="Read More" />
            </Link>
        </div>
    </div>
  )
}
