import  { useEffect, useState } from 'react'
import { axiosInstance, useAxiosInterceptor } from '../../Interceptors/authInterceptor';
import { Query } from '../AboutUsPage';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinner } from '../../Components/LoadingSpinner';
import { Modal } from '../../Components/Modal';

export const AdminMessages = () => {
  useAxiosInterceptor();
  const [queries, setQueries] = useState<Query[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showModal, setShowModal] = useState(false);
  const [queryDetail, setQueryDetail] = useState<Query | null>();
  const navigate = useNavigate();
  
  

  const fetchProducts = async (page: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/query?page=${page}&limit=10`,
        {
          withCredentials: true,
        }
      );
      const { queries, lastPage } = response.data;
      setQueries(queries);
      setTotalPages(lastPage);
    } catch (error:any) {
      if(error.status === 403){
        navigate('/admin/blog')
      }
    }
  };
  
 
  useEffect(() => {
    if (!showModal) {
      setQueryDetail(null);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    fetchProducts(currentPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);


  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleViewQuery = async (id: string) => {
    setShowModal(true);
    const { data } = await axiosInstance.get(`/api/query/${id}`, {
      withCredentials: true,
    });
    setQueryDetail(data.query);
  };





  return (
    <>
    <div className="lg:px-40 mt-7">
        
        {!queries && <LoadingSpinner />}
        {queries && <table className="table-fixed w-[100%] border-collapse mb-10">
          <thead className="">
            <tr className="text-[1.2rem] ">
              <th className="py-3">Name</th>
              <th className="py-3">Subject</th>
              {/* <th className='py-3'>Products</th> */}
              <th className="py-3">Email</th>
              <th className="py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {queries &&
              queries.map((query: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className="text-center text-[1.1rem] font-medium  py-20 odd:bg-primary odd:bg-opacity-[0.15]"
                  >
                    <td className="py-2 ps-1">{query.name}</td>
                    <td>{query.subject}</td>
                    {/* <td>{quote.product[0].name}</td> */}
                    <td>{query.email}</td>
                    <td className="flex gap-5 justify-center items-center py-2 text-primary text-2xl">
                      <button
                        onClick={() => {
                          handleViewQuery(query._id);
                        }}
                      >
                        <FontAwesomeIcon icon={faFile} />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>}
        <div className="flex justify-center items-center gap-3 mt-5 text-xl mb-4">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div>
            Page{" "}
            <input
              className="w-10 outline-none text-center"
              type="number"
              value={currentPage}
              onChange={(e) => setCurrentPage(Number(e.target.value))}
            />{" "}
            <span className="me-2">Of</span> {totalPages}
          </div>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal}>
          <div className="flex flex-col ">
            <div
              className={`grid grid-cols-2 py-3 font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">Name:</strong>
              <span>{queryDetail?.name}</span>
            </div>
            <div className={`grid grid-cols-2 py-3  font-medium `}>
              <strong className="px-10">Subject:</strong>
              <span>{queryDetail?.subject} </span>
            </div>
            <div
              className={`grid grid-cols-2 py-3  font-medium bg-primary odd:bg-opacity-[0.15]`}
            >
              <strong className="px-10">Email:</strong>
              <span>{queryDetail?.email}</span>
            </div>
            <div className={`grid grid-cols-2 py-3  font-medium`}>
              <strong className="px-10">Message:</strong>
              <span>{queryDetail?.message}</span>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
