import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type SearchBarProps = {
    onChange: (search:string) => void;
}

export const SearchBar = ({onChange} : SearchBarProps) => {
  return (
    <div className="flex relative">
              <input
                className="border rounded-md px-8 py-2 outline-none sm:w-[20rem] w-[100%]  border-primary"
                type="text"
                placeholder="Search by Name/CAS/Catalog"
                onChange={(e)=>{onChange(e.target.value)}}
                name="search"
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="text-primary absolute top-3 left-2"
              />
        </div>
  )
}
