import { ReactNode } from "react"
type CardProps = {
  children: ReactNode,
  className?: string
}
export const Card = ({children, className}: CardProps) => {
  return (
    <div className={`flex flex-col gap-3  justify-center items-center  max-w-96 shadow-[9px_9px_29px_-5px_rgba(0,0,0,0.2)]  bg-white ${className ? `${className}` : ''} `}>
        {children}
    </div>
  )
}
  