export const nameReg = new RegExp("^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{3,50}$");
export const mailingAddressReg = new RegExp("^(?=.*[a-zA-Z])[\\w\\s.,#'()/-]+$");
export const cityReg = new RegExp("^[a-zA-Z.]+(?:[\\s-][a-zA-Z.]+)*$");
export const stateReg = new RegExp("^[a-zA-Z.]+(?:[\\s-][a-zA-Z.]+)*$");
 export const zipReg = new RegExp("^[0-9a-zA-Z\\-]+$");
 export const countryReg = new RegExp("^[a-zA-Z.]+(?:[\\s-][a-zA-Z.]+)*$");
 export const companyReg = new RegExp("^[a-zA-Z.0-9]+(?:[\\s-][a-zA-Z.0-9]+)*$");
 export const phoneReg = new RegExp("^[+]{0,1}(?:[0-9\\-\\(\\)\\/\\.]\\s?){6,15}[0-9]{1}$");
 export const emailReg = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$");

 export const pepetidSeqRegex = new RegExp(/^[A-Z]+$/);
 export const quantityReg = new RegExp("^[0-9]+(?:\\.[0-9]+)?$");

 export const productNameReg = new RegExp("^[A-Za-z0-9()\\-•\\s′,]{0,50}$");
 export const catalogNoReg = new RegExp("^[A-Z]{1,6}[0-9]{1,10}$");
 export const casNoReg = new RegExp("^[A-Za-z0-9\\-\(\)\\s]{0,100}$");
 export const shortDescReg = new RegExp(".{0,800}");
 export const msgReg = new RegExp("^[a-zA-Z0-9,.@\\s&$#-_]{0,1000}$");

 export const partNameReg = new RegExp("^[a-zA-Z0-9,.@\\s&$#-_]{1,100}$");
 export const partCatalogNoReg = new RegExp("^[0-9]{1,30}$");
 export const partPriceReg = new RegExp("^[0-9]{1,5}\\.{0,1}[0-9]{0,2}$");
 export const partQuantityReg = new RegExp("^[0-9]{0,4}$");
 export const partDescriptionReg = new RegExp("^[a-zA-Z0-9,.@\\s&$#-_]{0,200}$");
 export const productIdReg = new RegExp("^[a-z0-9]{24}$");

export const blogTitleRegex = new RegExp(/^[A-Za-z0-9\s?\\\/\.,\-?!@#\$%\^\&\*\+\-_\+\(\)<>\"'\[\]:]{1,100}$/)
