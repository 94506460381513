import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "../../Components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  axiosInstance,
  useAxiosInterceptor,
} from "../../Interceptors/authInterceptor";
import { LoadingSpinner } from "../../Components/LoadingSpinner";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { ToastContainer , toast } from "react-toastify";

export type Blog = {
  id: string;
  title: string;
  description: string;
  image: string | File;
  tags: string[];
  thumbnail: string;
  readCount: number;
  createdAt: Date | null;
  updatedAt: Date | null;
  slug: string;
  state?: string;
};

export const AdminBlog = () => {
  useAxiosInterceptor();
  const [blogs, setBlogs] = useState<Blog[]>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {user} =  useAuthContext(); 
  const navigate= useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!user.user?.accessibility[2]) {
      navigate('/admin/unauthorized');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (location.state && location.state.showToast) {
      toast.success(location.state.showToast);
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location]);// eslint-disable-line react-hooks/exhaustive-deps
  const fetchProducts = async (page: number) => {
    try {
      const response = await axiosInstance.get(
        `/api/admin/posts?page=${page}&limit=10`,
        {
          withCredentials: true,
        }
      );
      const { posts, lastPage } = response.data;
      setBlogs(posts);
      setTotalPages(lastPage);
    } catch (error) {
        console.error(error);
    }
};

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);


  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const cell = e.target as HTMLElement;

    const button = cell.parentElement?.parentElement;
    const id = button?.dataset.id;
    try {
      if (window.confirm("Are you sure to delete this blog?")) {
        await axiosInstance.delete(`/api/posts/${id}`, {
          withCredentials: true,
        });

        setBlogs((prevBlogs) =>
          prevBlogs?.filter((blog) => blog.id !== id)
        );
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="px-40 mt-7">
      <div className="flex justify-end">
      <Link to="/admin/create-blog">
          <CustomButton title="Add Blog" className=" mb-7" />
        </Link>
      </div>
      {!blogs && 
          <div className="flex justify-center items-center " >
            <LoadingSpinner />
          </div>
          }
      {blogs && <table className="table-fixed w-[100%]">
      <colgroup>
        <col style={{width: '5%'}} />
        <col style={{width: '60%'}} />
        <col style={{width: '10%'}} />
        <col style={{width: '10%'}} />
        <col style={{width: '15%'}}  />
      </colgroup>
        <thead>
          <tr>
            <th className="text-left">Sr. No</th>
            <th className="text-left">Title</th>
            <th className="text-left">ReadCount</th>
            <th className="text-left">Status</th>
            <th className="text-left">Actions</th>
          </tr>
        </thead>
        
        <tbody>
          
          {blogs &&
            blogs.map((blog, index) => {
              return (
                <tr key={index} className=" text-[0.97rem] font-medium  py-20 odd:bg-primary odd:bg-opacity-[0.15]">
                    <td className="ps-1">{index+1}</td>
                  <td className="text-left">{blog.title}</td>
                  <td>{blog.readCount}</td>
                  <td>{blog.state}</td>
                  <td className="flex gap-5 justify-start items-center py-2 text-primary text-2xl">
                    <Link to={`/admin/edit-blog/${blog.id}`}>
                      <button>
                        <FontAwesomeIcon icon={faPencil} />
                      </button>
                    </Link>
                    <button
                       onClick={handleDelete}
                       data-id={`${blog.id}`}
                    >
                      <FontAwesomeIcon className="" icon={faTrash} />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>}
      <div className="flex justify-center items-center gap-3 mt-5 text-xl mb-4">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div>
          Page{" "}
          <input
            className="w-10 outline-none text-center"
            type="number"
            value={currentPage}
            onChange={(e) => setCurrentPage(Number(e.target.value))}
          />{" "}
          <span className="me-2">Of</span> {totalPages}
        </div>

        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
