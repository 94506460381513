import {  useEffect, useState } from "react";
import { BlogCard } from "./BlogCard"
import { Blog } from "../Pages/Admin/AdminBlog";
import axios from "axios";
import { CardSlider } from "./CardSlider";



export const Blogs = () => {

  const [blogs, setBlogs] = useState<Blog[]>([]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `/api/posts?page=1&limit=8`
      );
      const { posts } = response.data;
      setBlogs([...blogs,  ...posts]);
      
    } catch (error) {
        console.error(error);
    }
};

useEffect(()=>{

  fetchProducts();
},[])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="xl:px-32 lg:px-20 md:px-10 px-5 my-10">
        <h3 className="text-3xl font-bold mb-10">Blogs & Articles</h3>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
          {blogs && blogs.length <= 3 &&
            blogs.map((blog)=>{
              return (
                <BlogCard key={blog.id} blog={blog} />
              )
            })}
        </div>
        {blogs && blogs.length > 3 && <CardSlider  >
          {blogs.map((blog)=>{
            return (
              <BlogCard key={blog.id} blog={blog} />
            )
        })}
        </CardSlider>}
    </div>
  )
}
