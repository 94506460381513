

type ErrorSpanProps = {
  error: string
  className?: string
}

export const ErrorSpan = ({error, className} : ErrorSpanProps) => {
  return (
    <span className={`absolute -bottom-5 text-sm ms-1 text-red-500 ${className ? className : ""}`}>{error}</span>
  )
}
