import { Link, useLocation, useNavigate } from 'react-router-dom'
import PMLogo from '../assets/PMLogo.svg'
import { CustomButton } from './CustomButton'
import axios from 'axios'
import { useAuthContext } from '../Hooks/useAuthContext'
import { useState } from 'react'



export const AdminNavbar = () => {
  // eslint-disable-next-line
    const [error, setError] = useState<string>();
    const {user, dispatch} = useAuthContext();
    const navigate = useNavigate();
    const handleLogout = async()=>{
        try{
          const res = await axios.post('/auth/logout',null,{withCredentials:true});
          localStorage.removeItem('user');
          if(res.status === 200){
            localStorage.removeItem('user');
            dispatch({type:"LOGOUT"});
            navigate('admin/login')
          }
        } catch(e: any){
          setError(e.message)
        }
      }

    const location = useLocation();
    if(location.pathname.includes("login") || !location.pathname.includes('admin')){
      return <></>
    }

    return (
      
        <nav className='flex items-center justify-between px-40 h-14 sticky top-0 z-20 bg-white'>
            <Link to={user && user.user?.role === 0 ? '/admin/products' : '/admin/blog'}><img src={PMLogo} alt="Peptide Machines" /></Link>
            <div className='flex gap-6 font-semibold text-[17px]  items-center'>
                {user && user.user?.accessibility[0] && <Link to="/admin/products">Products</Link>}
                {user && user.user?.accessibility[1] && <Link to="/admin/parts-and-consumables">Parts & Consumables</Link>}
                 {user && user.user?.accessibility[2] && <Link to="/admin/blog">Blog</Link>}
                {user && user.user?.accessibility[3] && <Link to="/admin/peptide-quotes">Peptide Sythesis</Link>}
                {user && user.user?.accessibility[4] && <Link to="/admin/quotes">Quotes</Link>}
                { <Link to="/admin/messages">Messages</Link>}
                <CustomButton title='Logout' onClick={handleLogout}/>
            </div>
        </nav> 
      )
}
