
import { Link } from 'react-router-dom'
import { useSetPageTitle } from '../Hooks/useSetPageTitle'

export const ErrorPage = () => {
  useSetPageTitle("Not Found");
  return (
    <div className='flex flex-col justify-center items-center my-10'>
        <span className='text-[10rem] font-bold'>4<span className='text-primary'>0</span>4</span>
        <p className='text-2xl'>The Page you requested could not found </p>
        <Link to="/" className='text-xl underline mt-3'>Click Here go to home page</Link>
    </div>
  )
}
