import { useLocation } from "react-router-dom";

export interface HeaderStripProps {
  scrollToFaq: any
}


export const HeaderStrip = ({scrollToFaq} : HeaderStripProps) => {
  const location = useLocation();
  if (location.pathname.includes("login") || location.pathname.includes("admin") || location.pathname.includes("error")) {
    return <></>;
  }
  return (
    <header className="bg-primary flex  justify-between xl:px-32 lg:px-20 md:px-10 px-5 h-8 items-center">
        <a href="mailto:sales@peptidemachines.com" className="font-semibold text-white text-[0.73rem]  sm:text-sm md:text-base">sales@peptidemachines.com</a>
        <button onClick={scrollToFaq} className="font-semibold text-white text-[0.73rem] sm:text-sm  md:text-base cursor-pointer">Have any question?</button>
    </header>
  )
}
