
import FAQAccordian from './FAQAccordian'
import { CustomButton } from './CustomButton'
import { Link } from 'react-router-dom'
import { forwardRef } from 'react'

export const Faq = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className='bg-primary bg-opacity-[0.3] py-10 xl:px-32 lg:px-20 md:px-10 px-5' ref={ref}>
        <h3 className='text-3xl font-bold mb-5'>FAQ</h3>
        <FAQAccordian />    
        <div className='flex justify-end '>
          <Link to="request-quote">
            <CustomButton title="Request A Quote" className="mt-5 "/>
          </Link>
        </div>
    </div>
  )
})
