import axios from "axios";
import { useContext, useEffect } from "react"; 
import { AuthContext } from "../Contexts/AuthContext";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create();

export const useAxiosInterceptor = () => {
    const { state: user, dispatch } = useContext(AuthContext); 

    useEffect(() => {
        let refresh = false;

        const interceptor = axiosInstance.interceptors.response.use(
            resp => {
                return resp;
            },
            async error => {
                
                if (error.response.status === 401 && !refresh) {
                    refresh = true;
                    try {
                        const response = await axios.post('/auth/refresh', {}, { withCredentials: true });
                        
                        if (response.status === 200) {
                            refresh = false;
                            return axios(error.config);
                        }
                    } catch (refreshError: any) {
                        if (refreshError.response.status === 401) {
                            try {
                                const res = await axios.post('/auth/logout', null, { withCredentials: true });
                                dispatch({type: 'LOGOUT'});
                                if (res.status === 200) {
                                    window.location.href = '/admin/login';
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        }
                        console.error('Failed to refresh token:', refreshError);
                        refresh = false;
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.response.eject(interceptor); 
        };
    }, [user]);
};
