import { Product } from "./Product";
import { forwardRef, useEffect, useState } from "react";
import { useAxios } from "../Hooks/useAxios";

export type ProductType = {
  id: string;
  name: string;
  category: number;
  catalogNo: string;
  casNo: string;
  shortDescription: string;
  keyPoints: string[];
  image: File;
  slug: string;
  availability: number;
};

export const Products = forwardRef<HTMLDivElement>((props, ref) => {
  const [products, setProducts] = useState<ProductType[]>();

  const { response } = useAxios({
    method: "get",
    url: "/api/product?category=1&page=1&limit=100",
  });

  useEffect(() => {
    if (response != null) {
      setProducts(response.data.products);
    }
  }, [response]);

  return (
    <div ref={ref}>
      <h2 className="font-bold text-[1.5rem] md:text-3xl mt-7 xl:px-32 lg:px-20 md:px-10 px-5 mb-2 md:mb-0">
        Our Products
      </h2>
      {products &&
        products.map((product, index) => {
          return (
            <Product key={index} product={product} odd={index % 2 === 1} />
          );
        })}
    </div>
  );
});
