import Quote from '../assets/quote.webp'
import { Link } from 'react-router-dom'
import { CustomButton } from './CustomButton'
export const QuotePeptide = () => {
  return (
    <div className='relative'>
  <div className='absolute inset-0 bg-cover md:hidden  -z-10' style={{backgroundImage: `url(${Quote})`, opacity:'0.2'}}></div>
    <div className='grid md:grid-cols-2 py-10 md:py-0 md:mb-10  items-center gap-10 xl:px-32 lg:px-20 md:px-10 px-5 '>
        <div className='flex flex-col gap-3 md:gap-5 justify-between'>
            <h2 className='md:text-3xl text-2xl font-bold'>Peptide Synthesis</h2>
            <span className='md:text-xl text-lg font-semibold'>Solid-Phase Peptide Synthesis (SPPS)</span>
            <p className='md:text-lg text-base md:leading-8 leading-6  md:my-2 font-medium'>
                Peptide synthesis is the process of creating peptides, which are short chains of amino acids linked together by peptide bonds. Peptides play a crucial role in various biological functions, and synthetic peptide production is essential for research, drug development, and other applications. There are two main approaches to peptide synthesis: solid-phase synthesis and liquid-phase synthesis.
            </p>
            <Link to={'quote-a-peptide'}>
                    <CustomButton title="Quote a Peptide" />
            </Link>
        </div>
            <img  src={Quote} alt="" className='hidden md:block'/>
        
    </div>
    </div>
  )
}

